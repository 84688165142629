<template>
  <v-btn fab dark small
         class="ma-2 float-end"
         color="black"
         id="catalogue-production-close-button"
         @click="$emit('click', $event)"
  >
    <svg class="pl_icon50" role="img" id="close-icon">
      <use xlink:href="@/assets/pl_icon_close50.svg#pl_icon_close50"/>
    </svg>
  </v-btn>
</template>

<script>
export default {
  name: 'CloseButton',
};
</script>

<style scoped lang="scss">
.pl_icon50 {
  fill: $pl_color-white100;
  height: 15px;
  width: 15px;
}
</style>
