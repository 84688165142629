<template>
  <div>
    <template>
      <div class="work-page-assets pa-10">
        <div v-for="asset in assets"
             :key="asset.fileName"
             :data-originalFileName="asset.originalFileName"
             class="work-page-asset d-flex flex-column">
          <v-card
            class="work-page-asset-item"
            outlined>
            <div class="work-page-assets-thumbnail">
              <v-img v-if="getAssetThumbnails[asset.fileName]"
                     :src="`${getAssetThumbnails[asset.fileName]}`"
                     @error="reloadThumbnails()"
                     eager
                     height="140"
                     class="work-page-assets-thumbnail-image"/>
              <svg class="work-page-assets-icon" role="img" v-else>
                <use xlink:href="@/assets/pl_icon_camera.svg#pl_icon_camera"/>
              </svg>
            </div>
            <v-card-actions class="work-page-asset-details">
              <v-col class="pl-0 mt-1">
                <v-tooltip top nudge-right="6">
                  <template v-slot:activator="{ on, attrs }">
                    <p data-test="asset-filename" class="text-truncate" v-bind="attrs"
                       v-on="on" >
                      {{ asset.displayName }}
                    </p>
                  </template>
                  <span>{{ asset.displayName }}</span>
                </v-tooltip>
                <span
                  class="d-flex work-page-asset-uploading-icon-wrapper mt-n3">
                <ActionIcons :asset="asset"/>
              </span>
              </v-col>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </template>
    <div v-if="loading" class="workpage-assets-circular-loader">
        <v-progress-circular indeterminate color="blue" class="mx-4" :value="40" />
    </div>
  </div>
</template>

<script>
import workPageAssets from '@/mixins/workPageAssets';
import ActionIcons from './ActionIcons.vue';

export default {
  name: 'WorkPageAssets',
  components: { ActionIcons },
  props: { assets: Array },
  mixins: [workPageAssets],
};
</script>

<style scoped lang="scss">
.work-page-assets {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-row-gap: 40px;
  grid-column-gap: 20px;
  padding-bottom: 160px !important;
}
.work-page-assets-thumbnail {
  background-color: $black50;
  height: 140px;
  position: relative;
}
.work-page-assets-icon {
  fill: $pl_color-white100;
  height: 32px;
  width: 32px;
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  margin: auto;
}
.workpage-assets-circular-loader {
  text-align: center;
}
.work-page-asset-uploading-icon-wrapper {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: $grey50;
}
.work-page-asset-uploading-icon {
  color: $black50;
}
.work-page-assets-status-message {
  font-size: 12px;
}
.work-page-asset {
  .v-card {
    pointer-events: auto;
  }
  .v-card--disabled>:not(.v-card__progress) {
    opacity: 0.2;
  }
}
.work-page-asset-item {
  border-radius: 8px;
}
.work-page-asset-selected {
  background: rgba(80, 204, 127, 0.2);
}
.work-page-asset-checkbox{
  top: 8px;
  left: 8px;
  position: absolute;
  height: 24px;
  width: 24px;
}
.work-page-asset-details {
  height: 72px;
}
::v-deep .action-icon-extra-spacing {
  margin-right: 90px;
}
.work-page-assets-thumbnail-image {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>
