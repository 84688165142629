var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upload-progress",class:_vm.uploadProgressDynamicStyle},[_c('div',{staticClass:"upload-progress-title upload-progress-spacing d-flex justify-space-between\n  align-center"},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.title))]),_c('div',[(_vm.uploadFailed)?_c('v-btn',{staticClass:"retry-button",attrs:{"icon":""},on:{"click":_vm.retryFailedUploads}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"retry-button-icon",attrs:{"color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}],null,false,2453708582)},[_c('span',{staticClass:"upload-progress-asset-name"},[_vm._v("Retry Failed Assets")])])],1):_vm._e(),_c('v-btn',{staticClass:"collapse-button",attrs:{"icon":""},on:{"click":_vm.handleCollapse}},[(_vm.isUploadProgressCollapsed)?_c('v-icon',{staticClass:"collapse-button-icon",attrs:{"color":"white"}},[_vm._v(" mdi-chevron-up ")]):_c('v-icon',{staticClass:"collapse-button-icon",attrs:{"color":"white"}},[_vm._v("mdi-chevron-down")])],1)],1)]),(_vm.showTimeLeft)?_c('div',{staticClass:"upload-progress-subtitle px-6 d-flex justify-space-between align-center"},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.estimatedTimeToUpload))]),_c('v-btn',{staticClass:"cancel-all-button",attrs:{"small":"","text":""},on:{"click":_vm.handleCancelAllAssets}},[_vm._v("Cancel")])],1):_vm._e(),(!_vm.isUploadProgressCollapsed)?_c('div',{staticClass:"upload-progress-assets"},_vm._l((_vm.getAssetsInFlight),function(asset,index){return _c('div',{key:index,staticClass:"upload-progress-asset px-4 d-flex justify-space-between align-center",class:_vm.disabledAssetClass(asset)},[_c('span',{staticClass:"d-flex"},[_c('svg',{staticClass:"upload-progress-icon",attrs:{"role":"img"}},[_c('use',{attrs:{"xlink:href":require("@/assets/pl_icon_camera.svg") + "#pl_icon_camera"}})]),_c('p',{staticClass:"upload-progress-asset-name text-wrap ml-4 mb-0"},[_vm._v(_vm._s(asset.fileName))])]),(asset.s3UploadStatus === _vm.assetUploadStatus.FAILED
            || asset.fileNameValidationStatus === _vm.fileNameValidationStatus.status.INVALID)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-octagon ")])]}}],null,true)},[_c('span',{attrs:{"data-test":"s3-upload-status"}},[_vm._v(" "+_vm._s((asset.fileNameValidationStatus === _vm.fileNameValidationStatus.status.INVALID) ? ("Validation Failed: " + (asset.validationErrorMessage)) : _vm.$t('uploadFailureMessage.s3UploadFailure')))])])],1):(asset.s3UploadStatus === _vm.assetUploadStatus.DONE)?_c('StatusIcon',{attrs:{"status":'success'}},[_c('svg',[_c('use',{attrs:{"xlink:href":require("@/assets/pl_icon_checked_small.svg") + "#pl_icon_checked_small"}})])]):(asset.s3UploadStatus === _vm.assetUploadStatus.IN_PROGRESS)?_c('v-progress-circular',{attrs:{"value":_vm.percentageUploaded(asset),"color":"primary","size":24}}):_vm._e(),(asset.s3UploadStatus === _vm.assetUploadStatus.PENDING)?_c('v-icon',{staticClass:"cancel-button",attrs:{"color":"black"},on:{"click":function($event){return _vm.handleAssetCancel(asset)}}},[_vm._v(" mdi-close-circle ")]):_vm._e(),(asset.s3UploadStatus === _vm.assetUploadStatus.CANCELLED)?_c('p',{staticClass:"cancelled-status mb-0"},[_vm._v(" Cancelled ")]):_vm._e()],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }