var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-2 work-page-assets-list-layout",attrs:{"headers":_vm.headers,"items":_vm.filteredAssets,"search":_vm.search,"hide-default-footer":"","items-per-page":_vm.getListViewItemsPerPage,"page":_vm.page,"item-key":_vm.assets.fileName},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header.status",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.assetStatus ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('div',{staticClass:"asset-status-filter-list"},[_vm._l((_vm.assetStatusList),function(status){return _c('v-list-item',{key:status,staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.assetStatus=status}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(status))])],1)],1)}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.assetStatus = ''}}},[_vm._v("CLEAR")])],2)])]}},{key:"top",fn:function(){return [_c('v-row',{staticClass:"px-4 work-page-assets-list-layout-top-bar"},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-text-field',{staticClass:"mx-4 work-page-assets-list-layout-items-length",attrs:{"append-icon":_vm.loading ?
          'mdi mdi-loading mdi-spin' : '',"value":_vm.assets.length,"readonly":"","label":"Total Assets","type":"number"}}),_c('v-text-field',{staticClass:"work-page-assets-list-layout-items-per-page-input",attrs:{"value":_vm.getListViewItemsPerPage || '',"label":"Items per page","type":"number","min":"-1"},on:{"input":_vm.setItemsPerPage}}),_c('v-pagination',{staticClass:"mt-3",attrs:{"length":_vm.pageCount,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]},proxy:true},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',{staticClass:"work-page-assets-list-layout-item"},[_c('td',[(_vm.getAssetThumbnails[item.fileName])?_c('v-img',{staticClass:"work-page-assets-list-layout-thumbnail",attrs:{"eager":"","src":("" + (_vm.getAssetThumbnails[item.fileName]))},on:{"error":function($event){return _vm.reloadThumbnails()}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"work-page-assets-list-layout-icon-wrapper"},[_c('svg',{staticClass:"work-page-assets-list-layout-icon",attrs:{"role":"img"}},[_c('use',{attrs:{"xlink:href":require("@/assets/pl_icon_camera.svg") + "#pl_icon_camera"}})])])]},proxy:true}],null,true)}):_c('div',{staticClass:"work-page-assets-list-layout-icon-wrapper"},[_c('svg',{staticClass:"work-page-assets-list-layout-icon",attrs:{"role":"img"}},[_c('use',{attrs:{"xlink:href":require("@/assets/pl_icon_camera.svg") + "#pl_icon_camera"}})])])],1),_c('td',{staticClass:"work-page-assets-list-layout-data-asset-name work-page-asset-selectable-text",attrs:{"data-test":item.fileName}},[_vm._v(" "+_vm._s(item.displayName)+" ")]),_c('td',[_c('div',{staticClass:"work-page-assets-list-layout-s3-uploaded-at"},[_vm._v(" "+_vm._s(_vm.formatDate(item.s3UploadedAt)))])]),(_vm.assetType === _vm.ASSET_TYPE.IMAGE)?_c('td',[_c('div',{staticClass:"work-page-assets-list-layout-data-figure-number"},[_vm._v(" "+_vm._s(item.figureNumber))])]):_vm._e(),(_vm.assetType === _vm.ASSET_TYPE.DESIGN_DOC)?_c('td',[_vm._v(_vm._s(item.pagina))]):_vm._e(),(_vm.assetType === _vm.ASSET_TYPE.DESIGN_DOC)?_c('td',[_vm._v(_vm._s(item.documentStatus))]):_vm._e(),_c('td',[_c('div',{staticClass:"work-page-assets-list-layout-data-article-numbers"},[_vm._v(" "+_vm._s(_vm.formatDate(item.sharedienUploadStatus.uploadedAt)))])]),_c('td',[_c('ActionIcons',{attrs:{"asset":item}})],1)])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }