<template>
    <v-data-table
    :headers="headers"
    :items="filteredAssets"
    :search="search"
    hide-default-footer
    :items-per-page="getListViewItemsPerPage"
    :page.sync="page"
    @page-count="pageCount = $event"
    :item-key="assets.fileName"
    class="elevation-2 work-page-assets-list-layout"
  >

      <template v-slot:[`header.status`]="{ header }">
        {{ header.text }}
        <v-menu offset-y :close-on-content-click="true">
          <div class="asset-status-filter-list">
            <v-list-item
              style="cursor: pointer;"
              @click = 'assetStatus=status'
              v-for="status in assetStatusList" :key="status">
              <v-list-item-content>
                <v-list-item-title>{{status}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-btn
              @click="assetStatus = ''"
              small
              text
              color="primary"
              class="ml-2 mb-2"
            >CLEAR</v-btn>
          </div>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small :color="assetStatus ? 'primary' : ''">
                mdi-filter
              </v-icon>
            </v-btn>
          </template>
        </v-menu>
      </template>

      <template v-slot:top>
        <v-row class="px-4 work-page-assets-list-layout-top-bar">
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
          <v-text-field
            :append-icon="loading ?
            'mdi mdi-loading mdi-spin' : ''"
            :value="assets.length"
            class="mx-4 work-page-assets-list-layout-items-length"
            readonly
            label="Total Assets"
            type="number">
          </v-text-field>
          <v-text-field
            :value="getListViewItemsPerPage || ''"
            class="work-page-assets-list-layout-items-per-page-input"
            label="Items per page"
            type="number"
            min="-1"
            @input="setItemsPerPage"
          ></v-text-field>
          <v-pagination
            class="mt-3"
            v-model="page"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </v-row>
      </template>

      <template v-slot:item="{ item }">
        <tr class="work-page-assets-list-layout-item">
          <td>
            <v-img v-if="getAssetThumbnails[item.fileName]"
                   @error="reloadThumbnails()"
                   eager
                   class="work-page-assets-list-layout-thumbnail"
                   :src="`${getAssetThumbnails[item.fileName]}`">
              <template v-slot:placeholder>
                <div class="work-page-assets-list-layout-icon-wrapper">
                  <svg class="work-page-assets-list-layout-icon" role="img">
                    <use xlink:href="@/assets/pl_icon_camera.svg#pl_icon_camera"/>
                  </svg>
                </div>
              </template>
            </v-img>
            <div class="work-page-assets-list-layout-icon-wrapper" v-else>
              <svg class="work-page-assets-list-layout-icon" role="img">
                <use xlink:href="@/assets/pl_icon_camera.svg#pl_icon_camera"/>
              </svg>
            </div>
          </td>
          <td class="work-page-assets-list-layout-data-asset-name work-page-asset-selectable-text"
              :data-test="item.fileName">
            {{ item.displayName }}
          </td>
          <td>
            <div class="work-page-assets-list-layout-s3-uploaded-at">
              {{ formatDate(item.s3UploadedAt) }}</div>
          </td>
          <td v-if="assetType === ASSET_TYPE.IMAGE">
            <div class="work-page-assets-list-layout-data-figure-number">
              {{ item.figureNumber }}</div>
          </td>
          <td v-if="assetType === ASSET_TYPE.DESIGN_DOC">{{ item.pagina }}</td>
          <td v-if="assetType === ASSET_TYPE.DESIGN_DOC">{{ item.documentStatus }}</td>
          <td>
            <div class="work-page-assets-list-layout-data-article-numbers">
              {{ formatDate(item.sharedienUploadStatus.uploadedAt) }}</div>
          </td>
          <td>
            <ActionIcons :asset="item"/>
          </td>
        </tr>
      </template>
  </v-data-table>
</template>

<script>

import { mapGetters } from 'vuex';
import workPageAssets from '@/mixins/workPageAssets';
import { getAssetType } from '@/modules/getApplicationUserRole';
import { ASSET_TYPE, RENAME_ASSETS_STATUS, SHAREDIEN_SYNC_STATUS } from '@/modules/constants';
import ActionIcons from './ActionIcons.vue';

export default {
  name: 'WorkPageAssetsListLayout',
  props: {
    assets: Array,
  },
  components: { ActionIcons },
  mixins: [workPageAssets],
  data() {
    return {
      assetStatus: '',
      search: '',
      ASSET_TYPE,
      page: 1,
      pageCount: 0,
      assetStatusList: [
        this.$t('assetStatus.metadata.pending'),
        this.$t('assetStatus.metadata.done'),
        this.$t('assetStatus.sync.pending'),
        this.$t('assetStatus.sync.done'),
        this.$t('assetStatus.sync.failed'),
      ],
      reproServiceProviderHeaders: [
        { text: this.$t('assetData.pagina'), value: 'pagina' },
        { text: this.$t('assetData.status'), value: 'documentStatus' },
      ],
      photographerHeaders: [
        { text: this.$t('assetData.figureNumber'), value: 'figureNumber' },
      ],
    };
  },
  computed: {
    ...mapGetters(['getUserRoles', 'getListViewItemsPerPage', 'getSelectedCatalogueKey',
      'getSelectedRouteCode', 'getSelectedWorkPage']),
    assetType() {
      return getAssetType(this.getUserRoles, this.$router);
    },
    filteredAssets() {
      let condition;
      const filterMethods = [
        this.filterMetadataPending,
        this.filterMetadataDone,
        this.filterSyncPending,
        this.filterSyncDone,
        this.filterSyncFailed,
      ];

      if (this.assetStatus) {
        const index = this.assetStatusList.indexOf(this.assetStatus);
        condition = filterMethods[index];
      }
      if (condition) {
        return this.assets.filter((asset) => condition(asset));
      }
      return this.assets;
    },
    headers() {
      return [
        {
          text: this.$t('assetViewerTableHeaders.preview'), align: 'start', sortable: false, filterable: false, value: 'thumbnailUrl',
        },
        { text: this.$t('assetViewerTableHeaders.assetName'), value: 'displayName' },
        { text: this.$t('assetViewerTableHeaders.uploadedAt'), value: 's3UploadedAt' },
        ...(this.assetType === ASSET_TYPE.IMAGE) ? this.photographerHeaders : [],
        ...(this.assetType === ASSET_TYPE.DESIGN_DOC) ? this.reproServiceProviderHeaders : [],
        { text: this.$t('assetData.sharedienSyncDate'), value: 'sharedienUploadStatus.uploadedAt' },
        {
          text: this.$t('assetViewerTableHeaders.assetStatus'), value: 'status', sortable: false, filterable: false,
        },
      ];
    },
  },
  methods: {
    filterMetadataPending(asset) {
      return !(asset[RENAME_ASSETS_STATUS.name]);
    },
    filterMetadataDone(asset) {
      return !!(asset[RENAME_ASSETS_STATUS.name]);
    },
    filterSyncPending(asset) {
      return this.filterMetadataDone(asset) && !(asset[SHAREDIEN_SYNC_STATUS.name].status);
    },
    filterSyncDone(asset) {
      return asset[SHAREDIEN_SYNC_STATUS.name].status === SHAREDIEN_SYNC_STATUS.status.DONE;
    },
    filterSyncFailed(asset) {
      return asset[SHAREDIEN_SYNC_STATUS.name].status === SHAREDIEN_SYNC_STATUS.status.FAILED;
    },
    setItemsPerPage(itemsPerPage) {
      this.$store.commit('setListViewItemsPerPage', parseInt(itemsPerPage, 10) || 0);
    },
    formatDate(date) {
      let event = '';
      if (Date.parse(date)) {
        event = new Date(date);
        return event.toLocaleString();
      }
      return event;
    },
  },
};
</script>

<style scoped lang="scss">
.work-page-assets-list-layout {
  margin: 30px 40px 160px;
}
.work-page-asset-list-layout-checkbox{
  height: 24px;
  width: 24px;
}
.work-page-assets-list-layout-item {
  pointer-events: auto;
  position: relative;
  user-select: none;
  overflow: hidden;
  touch-action: none;
}
.work-page-assets-list-layout-disabled-item {
  pointer-events: none;
  opacity: 0.2;
  .v-data-table {
    background-color: $pl_color-white100;
  }
}
.work-page-assets-list-layout-check-box-field {
  min-width: 56px;
}
.work-page-assets-list-layout-icon {
  fill: $pl_color-white100;
  height: 24px;
  width: 24px;
  top: 0; bottom: 0; left: 0; right: 0;
  margin: auto;
}
.work-page-assets-list-layout-icon-wrapper {
  background-color: $black50;
  max-width: 64px;
  max-height: 42px;
  min-width: 64px;
  min-height: 42px;
  border-radius: 4px;
  display: flex;
  align-content: center;
}
.work-page-assets-list-layout-thumbnail {
  max-width: 64px;
  max-height: 64px;
  border-radius: 4px;
}
.work-page-assets-list-layout-data-asset-name {
  min-width: 300px;
  max-width: 400px;
  word-break: break-word;
}
.work-page-assets-list-layout-data-figure-number {
  min-width: 130px;
  max-width: 130px;
}
.work-page-assets-list-layout-data-article-numbers {
  min-width: 130px;
  max-width: 300px;
}
.work-page-assets-list-layout-s3-uploaded-at {
  min-width: 130px;
  max-width: 300px;
}
.work-page-assets-list-layout-items-per-page-input {
  max-width: 100px;
}
.work-page-asset-list-layout-item-selected {
  background-color: rgba(80, 204, 127, 0.2) !important;
}
.work-page-asset-selectable-text {
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.work-page-assets-list-layout-items-length {
  max-width: 100px;
}

.asset-status-filter-list{
  background-color: $pl_color-white100;
}

.v-menu__content{
  left: 85% !important;
}
</style>
