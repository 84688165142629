<template>
  <v-icon
    @click="toggleLayout"
    class="work-page-asset-layout-selector">{{ getLayoutIcon }} mdi-36px</v-icon>
</template>

<script>
import { mapGetters } from 'vuex';
import { WORK_PAGE_ASSETS_LAYOUT_MODE } from '@/modules/constants';

export default {
  name: 'WorkPageAssetsLayoutSelector',
  computed: {
    ...mapGetters(['getWorkPageAssetsLayoutMode']),
    getLayoutIcon() {
      switch (this.getWorkPageAssetsLayoutMode) {
        case WORK_PAGE_ASSETS_LAYOUT_MODE.LIST_VIEW:
          return 'mdi-view-module';
        case WORK_PAGE_ASSETS_LAYOUT_MODE.GRID_VIEW:
          return 'mdi-view-list';
        default:
          return 'mdi-view-list';
      }
    },
  },
  methods: {
    toggleLayout() {
      switch (this.getWorkPageAssetsLayoutMode) {
        case WORK_PAGE_ASSETS_LAYOUT_MODE.LIST_VIEW:
          this.$store.commit('setWorkPageAssetsLayoutMode', WORK_PAGE_ASSETS_LAYOUT_MODE.GRID_VIEW);
          break;
        case WORK_PAGE_ASSETS_LAYOUT_MODE.GRID_VIEW:
          this.$store.commit('setWorkPageAssetsLayoutMode', WORK_PAGE_ASSETS_LAYOUT_MODE.LIST_VIEW);
          break;
        default:
          this.$store.commit('setWorkPageAssetsLayoutMode', WORK_PAGE_ASSETS_LAYOUT_MODE.GRID_VIEW);
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.work-page-asset-layout-selector.v-icon {
  color: $black50;
}
</style>
