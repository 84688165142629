import axios from 'axios';
import {
  WORK_PAGE_ASSETS_ENDPOINT,
  WORKPAGE_ENDPOINT,
  CREATE_WORKPAGE_ENDPOINT,
} from '@/modules/constants';

const { VUE_APP_GET_ALL_CATALOGUES_API } = process.env;

const getWorkPages = async (catalogueKey) => {
  const headers = {
    authorization: `Bearer ${localStorage.getItem('access_token')}`,
  };
  try {
    const receivedResponse = await axios.get(
      `${VUE_APP_GET_ALL_CATALOGUES_API}${WORKPAGE_ENDPOINT}/${catalogueKey}`, { headers },
    );
    return receivedResponse.data.workPages;
  } catch (error) {
    console.log('Error fetching work pages', error);
    return null;
  }
};

export const fetchWorkPageAssets = async (catalogueKey,
  routeCode, workPageNumber, type, limit, lastEvaluatedKey = undefined) => {
  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
    params: { type, limit, lastEvaluatedKey },
  };
  try {
    const response = await axios.get(`${VUE_APP_GET_ALL_CATALOGUES_API}${WORK_PAGE_ASSETS_ENDPOINT}/${catalogueKey}/${routeCode}/${workPageNumber}`, config);
    return {
      assets: response.data.assets,
      lastEvaluatedKey: response.data.exclusiveStartKey,
    };
  } catch (error) {
    console.log('Error fetching work page assets', error.message);
    return null;
  }
};

export const fetchCatalogueAssets = async (catalogueKey,
  type,
  limit,
  lastEvaluatedKey = undefined) => {
  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
    params: { type, limit, lastEvaluatedKey },
  };
  try {
    const response = await axios.get(`${VUE_APP_GET_ALL_CATALOGUES_API}${WORK_PAGE_ASSETS_ENDPOINT}/${catalogueKey}`, config);
    return {
      catalogueData: response.data.assets,
      lastEvaluatedKey: response.data.exclusiveStartKey,
    };
  } catch (error) {
    console.log('Error fetching work page assets', error.message);
    return null;
  }
};

export const createWorkPages = async (workPageData) => {
  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  };
  try {
    const response = await axios.post(`${VUE_APP_GET_ALL_CATALOGUES_API}${CREATE_WORKPAGE_ENDPOINT}`, workPageData, config);
    return {
      success: true,
      data: response.data.data,
      catalogueUpdatedAt: response.data.catalogueUpdatedAt,
    };
  } catch (error) {
    console.log('Error in creation of work pages');
    return { success: false };
  }
};

export default getWorkPages;
