<template>
  <div class="d-flex justify-center upload-xml-button">
    <v-file-input
      v-show="!uploadInProgress"
      accept="application/xml"
      class="ma-0 pa-0"
      :label="$t('actions.uploadXml')"
      outlined dense single-line v-model="xmlFile"
      @change="uploadXml"/>
    <v-progress-circular v-if="uploadInProgress" indeterminate />
    <InfoDialog v-model="uploadComplete" :title="dialogTitle" :message="dialogMessage" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import uploadXmlClient from '@/modules/clients/uploadXml';
import InfoDialog from '@/components/InfoDialog.vue';

export default {
  name: 'UploadXmlButton',
  components: { InfoDialog },
  data() {
    return {
      xmlFile: null, uploadInProgress: false, uploadComplete: false, dialogTitle: '', dialogMessage: '',
    };
  },
  computed: {
    ...mapGetters(['getSelectedCatalogueKey']),
  },
  methods: {
    async uploadXml(selectedFile) {
      if (selectedFile) {
        this.uploadInProgress = true;
        const { success, message, workPageDetails } = await uploadXmlClient(this.xmlFile);
        this.dialogTitle = success ? 'Success' : 'Error';
        this.dialogMessage = message;
        this.uploadInProgress = false;
        this.uploadComplete = true;
        this.xmlFile = null;
        if (success) {
          const payload = { all: true };
          this.$store.dispatch('setCatalogueDashboard', payload);
          this.$store.commit('setSelectedCatalogueKey', workPageDetails.catalogueKey);
          this.$store.commit('setCatalogueData', {
            catalogueKey: workPageDetails.catalogueKey,
            catalogueData: workPageDetails.workPages,
          });
          this.$emit('uploadSuccess');
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.upload-xml-button {
  min-width: 180px;
}
</style>
