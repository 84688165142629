<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ma-2 mr-4 mt-2" color="black" outlined text icon v-bind="attrs"
               v-on="on" @click="showUserGuideDialog">
          <v-icon>
            mdi-help
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('userGuide.label') }}</span>
    </v-tooltip>
    <UserGuideDialog
      data-test="user-guide-dialog"
      :image="userGuide.image"
      :userDialogLocalStorage="localStorageToggleDialog"
      :content="userGuide.content"
      @toggleDialog="setLocalStorageToggleDialog"
      v-model="toggleUserGuideButton"></UserGuideDialog>
  </div>
</template>

<script>
import UserGuideDialog from '@/components/UserGuideDialog.vue';
import {
  PHOTOGRAPHER_GUIDE_CONTENT,
  PHOTOGRAPHER_ROLE,
  PHOTOGRAPHER_USER_GUIDE_IMAGE,
  PRINT_USER_GUIDE_CONTENT,
  PRINT_USER_GUIDE_IMAGE,
  PRINT_USER_ROLE,
  REPRO_SERVICE_PROVIDER_ROLE,
  RS_PROVIDER_GUIDE_CONTENT,
  RS_PROVIDER_USER_GUIDE_IMAGE,
} from '@/modules/constants';
import { getApplicationUserRole } from '@/modules/getApplicationUserRole';

export default {
  name: 'UserGuideButton',
  components: { UserGuideDialog },
  data() {
    return {
      toggleUserGuideButton: false,
      localStorageToggleDialog: JSON.parse(localStorage.getItem('showUserGuideDialog')),
      currentRouter: this.$router,
    };
  },
  watch: {
    $route: {
      handler() {
        this.currentRouter = this.$router;
      },
      immediate: true,
    },
  },
  computed: {
    userGuide() {
      const guide = {};
      const roles = this.$store?.state?.keycloak?.tokenParsed?.role;
      const role = getApplicationUserRole(roles, this.currentRouter);
      if (role === PRINT_USER_ROLE) {
        guide.image = PRINT_USER_GUIDE_IMAGE;
        guide.content = PRINT_USER_GUIDE_CONTENT;
      }
      if (role === PHOTOGRAPHER_ROLE) {
        guide.image = PHOTOGRAPHER_USER_GUIDE_IMAGE;
        guide.content = PHOTOGRAPHER_GUIDE_CONTENT;
      }
      if (role === REPRO_SERVICE_PROVIDER_ROLE) {
        guide.image = RS_PROVIDER_USER_GUIDE_IMAGE;
        guide.content = RS_PROVIDER_GUIDE_CONTENT;
      }
      return guide;
    },
  },
  methods: {
    showUserGuideDialog() {
      this.toggleUserGuideButton = true;
    },
    setLocalStorageToggleDialog() {
      this.localStorageToggleDialog = false;
      localStorage.setItem('showUserGuideDialog', false);
    },
  },
};
</script>

<style scoped>

</style>
