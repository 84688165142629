import cloneDeep from 'lodash.clonedeep';
import set from 'lodash.set';
import get from 'lodash.get';
import merge from 'lodash.merge';
import { ASSET_UPLOAD_STATUS } from '@/modules/constants';

const getPathForAsset = (asset) => `${asset.catalogueKey}/${asset.routeCode}/${asset.workPageNumber}/${asset.fileName}`;

export default {
  initKeycloak(state, keycloak) {
    state.keycloak = keycloak;
  },
  setCatalogues(state, data) {
    state.catalogues = data;
  },
  setServiceProviders(state, data) {
    state.serviceProviders = data;
  },
  addNewCatalogue(state, catalogue) {
    state.catalogues.unshift(catalogue);
  },
  setLoadingStatus(state, { name, status }) {
    state.loading = { ...state.loading, [name]: status };
  },
  setErrorStatus(state, { name, status }) {
    state.error = { ...state.error, [name]: status };
  },
  setSelectedCatalogueKey(state, catalogueKey) {
    state.selectedCatalogueKey = catalogueKey;
  },
  setSelectedRouteCode(state, routeCode) {
    state.selectedRouteCode = routeCode;
  },
  setSelectedWorkPage(state, workPage) {
    state.selectedWorkPage = workPage;
  },
  setCollapseStatus(state, { catalogueHierarchyElement, status }) {
    state.collapseStatus = {
      ...state.collapseStatus,
      [catalogueHierarchyElement]: status,
    };
  },
  setCatalogueData(state, { catalogueKey, catalogueData }) {
    let updatedCatalogueData;
    if (!state.catalogueData[catalogueKey]) {
      updatedCatalogueData = catalogueData;
    } else {
      updatedCatalogueData = merge(state.catalogueData[catalogueKey], catalogueData);
    }
    state.catalogueData = {
      ...state.catalogueData,
      [catalogueKey]: updatedCatalogueData,
    };
  },
  setCatalogueHasAssets(state, status) {
    state.isCatalogueHasAssets = status;
  },
  removeCatalogue(state, catalogueKey) {
    state.catalogues = state.catalogues
      .filter((catalogue) => catalogue.CatalogueKey !== catalogueKey);
  },
  setAssetThumbnails(state, assetThumbnails) {
    state.workPageAssetThumbnails = assetThumbnails.reduce((prev, asset) => {
      const returnValue = { ...prev };
      const {
        fileName,
        thumbnailUrl,
      } = asset;
      returnValue[fileName] = thumbnailUrl;
      return returnValue;
    }, {});
  },
  addAssetThumbnails(state, assetThumbnails) {
    const assetsThumbnails = assetThumbnails.reduce((prev, asset) => {
      const returnValue = { ...prev };
      const {
        fileName,
        thumbnailUrl,
      } = asset;
      returnValue[fileName] = thumbnailUrl;
      return returnValue;
    }, {});

    state.workPageAssetThumbnails = {
      ...state.workPageAssetThumbnails,
      ...assetsThumbnails,
    };
  },
  setAssetThumbnail(state, asset) {
    const assetThumbnail = {
      [asset.fileName]: asset.thumbnailUrl,
    };
    state.workPageAssetThumbnails = {
      ...state.workPageAssetThumbnails,
      ...assetThumbnail,
    };
  },
  addAssetToCatalogue(state, {
    catalogueKey, asset,
  }) {
    const catalogueDataClone = cloneDeep(state.catalogueData);
    const assets = catalogueDataClone[catalogueKey];
    if (assets) {
      const oldAssetIndex = assets
        .findIndex((one) => one.fileName === asset.fileName);
      if (oldAssetIndex >= 0) {
        catalogueDataClone[catalogueKey][oldAssetIndex] = {
          ...catalogueDataClone[catalogueKey][oldAssetIndex], ...asset,
        };
      } else {
        catalogueDataClone[catalogueKey].push({
          ...asset,
        });
      }
    } else {
      catalogueDataClone[catalogueKey] = [];
      catalogueDataClone[catalogueKey].push({
        ...asset,
      });
    }
    state.catalogueData = catalogueDataClone;
  },
  addAssetToWorkPage(state, {
    catalogueKey, routeCode, workPageNumber, asset,
  }) {
    const catalogueDataClone = cloneDeep(state.catalogueData);
    const pathToWorkPageAssets = `${catalogueKey}.${routeCode}.workPages.${workPageNumber}.assets`;
    const workPageAssets = get(catalogueDataClone, pathToWorkPageAssets, []);
    const oldAssetIndex = workPageAssets
      .findIndex((one) => one.fileName === asset.fileName);
    if (oldAssetIndex >= 0) {
      workPageAssets[oldAssetIndex] = {
        ...workPageAssets[oldAssetIndex], ...asset,
      };
    } else {
      workPageAssets.push({
        ...asset,
      });
    }
    set(catalogueDataClone, pathToWorkPageAssets, workPageAssets);
    state.catalogueData = catalogueDataClone;
  },
  addAssetsToWorkPage(state, {
    catalogueKey, routeCode, workPage, assets,
  }) {
    const catalogueDataClone = cloneDeep(state.catalogueData);
    const pathToWorkPageAssets = `${catalogueKey}.${routeCode}.workPages.${workPage}.assets`;
    const workPageAssets = get(catalogueDataClone, pathToWorkPageAssets, []);
    assets.forEach((asset) => {
      const targetIndex = workPageAssets
        .findIndex((workPageAsset) => workPageAsset.fileName === asset.fileName);
      if (targetIndex >= 0) {
        workPageAssets[targetIndex] = { ...workPageAssets[targetIndex], ...asset };
      } else {
        workPageAssets.push({
          ...asset,
        });
      }
    });
    set(catalogueDataClone, pathToWorkPageAssets, workPageAssets);
    state.catalogueData = catalogueDataClone;
  },
  addAssetsInCatalogue(state, {
    catalogueKey, catalogueData,
  }) {
    const catalogueDataClone = cloneDeep(state.catalogueData);
    const assets = catalogueDataClone[catalogueKey] || [];
    catalogueData.forEach((update) => {
      const targetAssetIndex = assets
        .findIndex((one) => one.fileName === update.fileName);
      if (targetAssetIndex >= 0) {
        assets[targetAssetIndex] = {
          ...assets[targetAssetIndex], ...update,
        };
      } else {
        assets.push({ ...update });
      }
    });
    state.catalogueData = { ...state.catalogueData, [catalogueKey]: assets };
  },
  updateAssetInCatalogue(state, {
    catalogueKey, assetUpdate,
  }) {
    const catalogueDataClone = cloneDeep(state.catalogueData);
    const assets = catalogueDataClone[catalogueKey] || [];
    const targetAssetIndex = assets
      .findIndex((one) => one.fileName === assetUpdate.fileName);
    if (targetAssetIndex >= 0) {
      catalogueDataClone[catalogueKey][targetAssetIndex] = {
        ...catalogueDataClone[catalogueKey][targetAssetIndex], ...assetUpdate,
      };
      state.catalogueData = catalogueDataClone;
    }
  },
  updateAssetInWorkPage(state, {
    catalogueKey, routeCode, workPageNumber, assetUpdate,
  }) {
    const catalogueDataClone = cloneDeep(state.catalogueData);
    const pathToWorkPageAssets = `${catalogueKey}.${routeCode}.workPages.${workPageNumber}.assets`;
    const workPageAssets = get(catalogueDataClone, pathToWorkPageAssets, []);
    const targetIndex = workPageAssets
      .findIndex((asset) => asset.fileName === assetUpdate.fileName);
    if (targetIndex >= 0) {
      workPageAssets[targetIndex] = { ...workPageAssets[targetIndex], ...assetUpdate };
      set(catalogueDataClone, pathToWorkPageAssets, workPageAssets);
      state.catalogueData = catalogueDataClone;
    }
  },
  removeAssetFromCatalogue(state, {
    catalogueKey, fileName,
  }) {
    const catalogueDataClone = cloneDeep(state.catalogueData);
    const assets = catalogueDataClone[catalogueKey];
    catalogueDataClone[catalogueKey] = assets.filter((asset) => asset.fileName !== fileName);
    state.catalogueData = catalogueDataClone;
  },
  removeAssetFromWorkPage(state, {
    catalogueKey, routeCode, workPageNumber, fileName,
  }) {
    const catalogueDataClone = cloneDeep(state.catalogueData);
    const pathToWorkPageAssets = `${catalogueKey}.${routeCode}.workPages.${workPageNumber}.assets`;
    const workPageAssets = get(catalogueDataClone, pathToWorkPageAssets, []);
    const updatedWorkPageAssets = workPageAssets.filter((asset) => asset.fileName !== fileName);
    set(catalogueDataClone, pathToWorkPageAssets, updatedWorkPageAssets);
    state.catalogueData = catalogueDataClone;
  },
  addAssetInFlight(state, assetInFlight) {
    state.assetsInFlight.unshift(assetInFlight);
  },
  updateAssetInFlight(state, assetUpdate) {
    const assetsInFlight = cloneDeep(state.assetsInFlight);
    const targetIndex = assetsInFlight
      .findIndex((asset) => getPathForAsset(asset) === getPathForAsset(assetUpdate));
    if (targetIndex >= 0) {
      assetsInFlight[targetIndex] = assetUpdate;
    }
    state.assetsInFlight = assetsInFlight;
  },
  updateAssetsInFlight(state) {
    const assetsInFlight = cloneDeep(state.assetsInFlight);
    const updatedAssetsInFlight = assetsInFlight;
    assetsInFlight.forEach((assetInFlight, index) => {
      if (assetInFlight.s3UploadStatus === ASSET_UPLOAD_STATUS.PENDING) {
        updatedAssetsInFlight[index].s3UploadStatus = ASSET_UPLOAD_STATUS.CANCELLED;
      }
    });
    state.assetsInFlight = updatedAssetsInFlight;
  },
  removeAssetsInFlight(state, assets) {
    const assetsInFlight = cloneDeep(state.assetsInFlight);
    const fileNames = assets.map((asset) => asset.fileName);
    state.assetsInFlight = assetsInFlight.filter((asset) => !fileNames.includes(asset.fileName));
  },
  toggleUploadProgressCollapse(state) {
    state.isUploadProgressCollapsed = !state.isUploadProgressCollapsed;
  },
  setWebSocketConnection(state, connection) {
    state.webSocketConnection = connection;
  },
  setWebSocketMessages(state, message) {
    state.websocketMessages.push(message);
  },
  setWorkPageAssetsLayoutMode(state, mode) {
    state.workPageAssetsLayoutMode = mode;
  },
  updateCatalogueModificationTime(state, dataToUpdate) {
    const {
      catalogueKey, catalogueUpdatedAt,
    } = dataToUpdate;
    const catalogueToUpdate = state.catalogues.findIndex(
      (catalogue) => catalogue.CatalogueKey === catalogueKey,
    );
    if (catalogueToUpdate !== -1) {
      state.catalogues[catalogueToUpdate].UpdatedAt = catalogueUpdatedAt;
    }
  },
  setUploadSpeed(state, speed) {
    state.currentUploadSpeed = speed;
  },
  setLastRecordedTime(state, time) {
    state.lastRecordedTime = time;
  },
  setTotalBytesUploaded(state, bytes) {
    state.totalBytesUploaded = bytes;
  },
  resetUploadData(state) {
    if (state.assetsInFlight.every((asset) => asset.s3UploadStatus === ASSET_UPLOAD_STATUS.DONE
      || asset.s3UploadStatus === ASSET_UPLOAD_STATUS.FAILED
      || asset.s3UploadStatus === ASSET_UPLOAD_STATUS.CANCELLED)) {
      state.currentUploadSpeed = 0;
      state.lastRecordedTime = null;
      state.totalBytesUploaded = 0;
      state.connectionSpeedWindow = 1;
    }
  },
  increaseConnectionSpeedWindow(state) {
    if (state.connectionSpeedWindow < 6) {
      state.connectionSpeedWindow += 1;
    }
  },
  setListViewItemsPerPage(state, itemsPerPage) {
    state.listViewItemsPerPage = itemsPerPage;
  },
};
