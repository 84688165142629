import { STATE, WORK_PAGE_ASSETS_LAYOUT_MODE } from '@/modules/constants';

export default {
  loading: {
    [STATE.CREATE_CATALOGUES]: false,
    [STATE.CREATE_WORK_PAGES]: false,
    [STATE.CATALOGUES]: false,
    [STATE.WORK_PAGES]: false,
    [STATE.WORK_PAGE_ASSETS]: false,
    [STATE.FETCH_ASSETS]: false,
  },
  error: {
    [STATE.CREATE_CATALOGUES]: false,
    [STATE.CREATE_WORK_PAGES]: false,
    [STATE.CATALOGUES]: false,
    [STATE.WORK_PAGES]: false,
    [STATE.WORK_PAGE_ASSETS]: false,
  },
  keycloak: {},
  catalogues: [],
  catalogueData: {},
  workPageAssetThumbnails: {},
  selectedCatalogueKey: '',
  selectedRouteCode: '',
  selectedWorkPage: '',
  isCatalogueHasAssets: false,
  assetsInFlight: [],
  serviceProviders: {},
  collapseStatus: {},
  isUploadProgressCollapsed: false,
  webSocketConnection: null,
  websocketMessages: [],
  workPageAssetsLayoutMode: WORK_PAGE_ASSETS_LAYOUT_MODE.GRID_VIEW,
  listViewItemsPerPage: 50,
  currentUploadSpeed: 0,
  lastRecordedTime: null,
  totalBytesUploaded: 0,
  connectionSpeedWindow: 1,
};
