<template>
  <div>
    <v-btn class="upload-assets-button" @click="$refs.workPageAssetUpload.click()">
      <svg class="upload-assets-upload-icon" role="img">
        <use xlink:href="@/assets/pl_icon_plus.svg#pl_icon_plus"/>
      </svg>
      <p class="upload-assets-button-label">{{ $t('actions.upload') }}</p>
    </v-btn>
    <input
      ref="workPageAssetUpload" class="d-none"
      type="file" multiple :accept="inputAcceptTypes"
      @change="onFileChanged" @click="conditionalClear"/>
    <InfoDialog v-model="fileNameConflict" v-bind="fileNameConflictDialogProps"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoDialog from '@/components/InfoDialog.vue';
import {
  ASSET_UPLOAD_STATUS, VALID_ASSET_EXTENSIONS,
} from '@/modules/constants';
import fileNameConflict from '@/mixins/fileNameConflict';
import { getAssetType } from '@/modules/getApplicationUserRole';

export default {
  name: 'UploadAssetsButton',
  components: { InfoDialog },
  mixins: [fileNameConflict],
  data() {
    return { fileNameConflict: false };
  },
  computed: {
    ...mapGetters([
      'getUserRoles',
    ]),
    inputAcceptTypes() {
      return VALID_ASSET_EXTENSIONS.reduce((previous, current, index) => {
        const comma = index !== 0 ? ',' : '';
        return `${previous}${comma}.${current}`;
      }, '');
    },
  },
  methods: {
    conditionalClear(e) {
      if (this.getAssetsInFlight.every(
        (asset) => asset.s3UploadStatus === ASSET_UPLOAD_STATUS.DONE
        || asset.s3UploadStatus === ASSET_UPLOAD_STATUS.FAILED,
      )) {
        e.target.value = '';
      }
    },
    onFileChanged(e) {
      const type = getAssetType(this.getUserRoles, this.$router);
      const files = this.getFilesFromEvent(e);
      for (let fileIndex = 0; fileIndex < files.length; fileIndex += 1) {
        const fileName = files[fileIndex].name;
        if (this.checkFileNameConflict(fileName)) {
          this.fileNameConflict = true;
          return;
        }
      }
      this.$store.dispatch('uploadAssets', {
        files,
        type,
      });
    },
    getFilesFromEvent(e) {
      return e.target.files;
    },
  },
};
</script>

<style scoped lang="scss">
.upload-assets-button.v-btn {
  background-color: $red100;
  text-transform: none;
  color: $pl_color-white100;
  height: 48px;
  width: 200px;
  font-size: 16px;
  border-radius: 8px;
}
.upload-assets-upload-icon {
  height: 24px;
  width: 24px;
  fill: $pl_color-white100;
}
.upload-assets-button-label {
  margin: 0 auto;
}
</style>
