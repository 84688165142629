<template>
  <div>
    <v-dialog
      v-model="modalOpen"
      transition="dialog-bottom-transition"
      width="75%"
    >
      <v-card class="pa-8">
        <CloseButton @click="modalOpen = false"/>
        <h1 class="create-catalogue-form-title my-8">{{ $t('title.catalogueForm') }}</h1>
        <v-divider></v-divider>
        <p class="my-8 mb-10">{{ $t('message.catalogueForm') }}</p>
        <v-form ref="createCatalogueForm">
          <v-row align="end">
            <v-col class="py-0">
              <v-text-field
                dense
                :label="`${$t('catalogueDataFields.formFields.companyId')}*`" outlined
                data-test="create-catalogue-company-id"
                v-model="catalogueInfo.companyId"
                disabled />
            </v-col>
            <v-col class="py-0">
              <v-text-field
                dense
                :label="`${$t('catalogueDataFields.formFields.companyName')}*`" outlined
                data-test="create-catalogue-company-name"
                v-model="catalogueInfo.company"
                disabled />
            </v-col>
            <v-col class="py-0" md="7" lg="7" xl="7">
              <v-text-field
                dense :label="`${$t('catalogueDataFields.formFields.title')}*`" outlined
                data-test="create-catalogue-title"
                v-model="catalogueInfo.title" maxlength="100"
                :rules="[rules.required, rules.range(0, 100)]"/>
            </v-col>
          </v-row>
          <v-row align="end">
            <v-col class="py-0">
              <v-text-field
                dense :label="`${$t('catalogueDataFields.seasonId')}*`" outlined
                data-test="create-catalogue-season-id"
                v-model="catalogueInfo.seasonId"
                :rules="[rules.required, rules.numbers, rules.length(3)]"
                maxlength="3"/>
            </v-col>
            <v-col class="py-0">
              <v-select
                dense :label="`${$t('catalogueDataFields.seasonName')}*`" outlined
                data-test="create-catalogue-season-name"
                v-model="catalogueInfo.seasonName"
                :items="['FS', 'HW']"
                :rules="[rules.required]"/>
            </v-col>
            <v-col class="py-0">
              <v-text-field
                dense :label="`${$t('catalogueDataFields.formFields.type')}*`" outlined
                data-test="create-catalogue-type"
                @input="uppercase('catalogueType')"
                v-model="catalogueInfo.catalogueType"
                :rules="[rules.required, rules.alphaNumerics, rules.length(2)]" maxlength="2" />
            </v-col>
            <v-col class="py-0">
              <v-text-field
                dense :label="`${$t('catalogueDataFields.formFields.version')}*`"
                outlined
                data-test="create-catalogue-version"
                @input="uppercase('catalogueVersion')"
                v-model="catalogueInfo.catalogueVersion"
                :rules="[rules.required, rules.alphabets, rules.length(1)]"
                maxlength="1" />
            </v-col>
            <v-col class="py-0">
              <v-select
                dense :label="`${$t('catalogueDataFields.year')}*`" outlined
                data-test="create-catalogue-year"
                v-model="catalogueInfo.catalogueYear"
                :items="allowedCatalogueYears"
                :rules="[rules.required]" />
            </v-col>
          </v-row>
        </v-form>
        <v-divider class="my-8"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Button :label="`${$t('actions.clear')}`" data-test="create-catalogue-clear-button"
                  color="black" @click="clearData" />
          <Button :label="`${$t('actions.submit')}`" data-test="create-catalogue-submit-button"
                  @click="submitHandler" />
        </v-card-actions>
      </v-card>
      <ConfirmationDialog
        v-model="confirmCatalogueCreation"
        :title="$t('title.createCatalogueConfirmation')"
        :body="$t('confirmationMessages.createCatalogue')"
        @okayClicked="createCatalogueHandler"/>
    </v-dialog>
    <InfoDialog :title="$t('title.error')"
                :message="$t('errorMessages.createCatalogue')"
                v-model="showCreateCatalogueErrorDialog"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { rules } from '@/modules/vueUtils';
import Button from '@/components/Button.vue';
import CloseButton from '@/components/CloseButton.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import InfoDialog from '@/components/InfoDialog.vue';

const defaultCatalogueInfo = {
  company: 'Otto',
  companyId: 'OV',
  catalogueType: '',
  seasonId: '',
  seasonName: '',
  catalogueVersion: '',
  catalogueYear: '',
  title: '',
};

const getYearsBetween = (begin, end) => Array.from(
  { length: end - begin + 1 }, (v, k) => `${begin + k}`,
);

export default {
  name: 'CreateCatalogueForm',
  components: {
    InfoDialog, ConfirmationDialog, CloseButton, Button,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      rules,
      catalogueInfo: {
        ...defaultCatalogueInfo,
      },
      confirmCatalogueCreation: false,
    };
  },
  computed: {
    ...mapGetters(['getCreateCatalogueErrorStatus']),
    allowedCatalogueYears() {
      const currentYear = new Date().getFullYear();
      return getYearsBetween(currentYear - 1, currentYear + 2);
    },
    modalOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        this.clearData();
      },
    },
    showCreateCatalogueErrorDialog: {
      get() {
        return this.getCreateCatalogueErrorStatus;
      },
      set() {
        this.$store.commit('setErrorStatus', { name: 'createCatalogues', status: false });
      },
    },
  },
  methods: {
    clearData() {
      this.catalogueInfo = { ...defaultCatalogueInfo };
      this.$refs.createCatalogueForm.resetValidation();
    },
    createCatalogueHandler() {
      this.$store.dispatch('createCatalogue', { catalogueInfo: this.catalogueInfo });
      this.modalOpen = false;
    },
    submitHandler() {
      if (this.$refs.createCatalogueForm.validate()) {
        this.confirmCatalogueCreation = true;
      }
    },
    uppercase(field) {
      this.catalogueInfo[field] = this.catalogueInfo[field].toUpperCase();
    },
  },
};
</script>

<style scoped lang="scss">
.create-catalogue-form-title {
  font-weight: 400;
}
</style>
