<template>
  <Assets :loading="loading" :uploadedAssets="uploadedAssets"></Assets>
</template>

<script>
import { getUploadedWorkPageAssets } from '@/modules/assetSelection';
import websocketHandler from '@/mixins/websocketHandler';
import Assets from '@/components/photographer/Assets.vue';

export default {
  name: 'ReproCatalogueDetails',
  components: {
    Assets,
  },
  mixins: [websocketHandler],
  computed: {
    loading() {
      return this.getWorkPagesLoadingStatus;
    },
    uploadedAssets() {
      return (this.getCatalogueData) ? getUploadedWorkPageAssets(this.getCatalogueData) : [];
    },
  },
  mounted() {
    this.$store.dispatch('webSocketSendMessage', {
      action: 'currentWorkPage',
      workPage: JSON.stringify({
        catalogueKey: this.getSelectedCatalogueKey,
      }),
    });
    this.getWebsocketConnection.onmessage = (message) => {
      this.validateAndStoreUpdate(message);
    };
  },
  methods: {
    validateAndStoreUpdate(message) {
      const update = JSON.parse(message.data);
      const { asset, operation, workPage } = update;
      const { catalogueKey } = workPage;
      if (this.isAssetOfSameCatalogue(catalogueKey) && this.isAssetOfSameType(asset.type)) {
        this.$store.dispatch('updateAssetsForPageSupplier', {
          catalogueKey: this.getSelectedCatalogueKey,
          operation,
          asset,
        });
      }
    },
  },
};
</script>

<style>
</style>
