import get from 'lodash.get';
import { STATE } from '@/modules/constants';

export default {
  getSelectedCatalogue: (state) => {
    const selectedCatalogue = state.catalogues
      .filter((catalogue) => catalogue.CatalogueKey === state.selectedCatalogueKey);
    return selectedCatalogue[0];
  },
  getMdlDropdownList: (state) => {
    const requiredMdlType = 'Repro';
    const requiredServiceProviders = state.serviceProviders[requiredMdlType] || [];

    return requiredServiceProviders.map((serviceProvider) => serviceProvider.mdl_kurz);
  },
  getSelectedCatalogueKey: (state) => state.selectedCatalogueKey,
  getSelectedRouteCode: (state) => state.selectedRouteCode,
  getCollapseStatus:
    (state) => (catalogueHierarchyKey) => state.collapseStatus[catalogueHierarchyKey],
  getCatalogueData: (state, getters) => state.catalogueData[getters.getSelectedCatalogueKey],
  getAssetThumbnails: (state) => state.workPageAssetThumbnails,
  // eslint-disable-next-line max-len
  getRouteCodeData: (state) => (catalogueKey, routeCode) => {
    const catalogueData = state.catalogueData[catalogueKey];
    return catalogueData ? catalogueData[routeCode] : catalogueData;
  },
  getSelectedWorkPage: (state) => state.selectedWorkPage,
  getWorkPageAssets: (state, getters) => {
    const catalogueKey = getters.getSelectedCatalogueKey;
    const routeCode = getters.getSelectedRouteCode;
    const workPageNumber = getters.getSelectedWorkPage;

    const pathToWorkPageAssets = `${catalogueKey}.${routeCode}.workPages.${workPageNumber}.assets`;
    return get(state.catalogueData, pathToWorkPageAssets, []);
  },
  isCatalogueHasAssets: (state) => state.isCatalogueHasAssets,
  getCataloguesLoadingStatus: (state) => state.loading[STATE.CATALOGUES],
  getWorkPagesLoadingStatus: (state) => state.loading[STATE.WORK_PAGES],
  getWorkPageAssetsLoadingStatus: (state) => state.loading[STATE.WORK_PAGE_ASSETS],
  fetchAssetsLoadingStatus: (state) => state.loading[STATE.FETCH_ASSETS],
  getAssetsInFlight: (state) => state.assetsInFlight,
  isUploadProgressCollapsed: (state) => state.isUploadProgressCollapsed,
  getCreateCatalogueLoadingStatus: (state) => state.loading[STATE.CREATE_CATALOGUES],
  getCreateCatalogueErrorStatus: (state) => state.error[STATE.CREATE_CATALOGUES],
  getCreateWorkPagesLoadingStatus: (state) => state.loading[STATE.CREATE_WORK_PAGES],
  getCreateWorkPagesErrorStatus: (state) => state.error[STATE.CREATE_WORK_PAGES],
  getWorkPageAssetsErrorStatus: (state) => state.error[STATE.WORK_PAGE_ASSETS],
  getWorkPageErrorStatus: (state) => state.error[STATE.WORK_PAGES],
  getUserRoles: (state) => state.keycloak.tokenParsed.role,
  getWebsocketMessages: (state) => state.websocketMessages,
  getWebsocketConnection: (state) => state.webSocketConnection,
  getWorkPageAssetsLayoutMode: (state) => state.workPageAssetsLayoutMode,
  getCatalogueKeys: (state) => state.catalogues.map((catalogue) => catalogue.CatalogueKey),
  getCurrentUploadSpeed: (state) => state.currentUploadSpeed,
  getTotalBytesUploaded: (state) => state.totalBytesUploaded,
  getLastRecordedTime: (state) => state.lastRecordedTime,
  getConnectionSpeedWindow: (state) => state.connectionSpeedWindow,
  getListViewItemsPerPage: (state) => state.listViewItemsPerPage,
};
