import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  PHOTOGRAPHER_HOME_PATH,
  PHOTOGRAPHER_ROLE,
  PRINT_USER_HOME_PATH,
  PRINT_USER_ROLE,
  REPRO_SERVICE_PROVIDER_HOME_PATH,
  REPRO_SERVICE_PROVIDER_ROLE,
} from '@/modules/constants';
import App from '@/App.vue';
import PrintUserHome from '@/views/PrintUserHome.vue';
import AssetViewerHome from '@/views/AssetViewerHome.vue';
import { isUserAuthorised } from '@/modules/authorizationService';
import RouteCode from '@/components/photographer/RouteCode.vue';
import CatalogueDashboard from '@/components/photographer/CatalogueDashboard.vue';
import CatalogueDetails from '@/components/photographer/CatalogueDetails.vue';
import WorkPage from '@/components/photographer/WorkPage.vue';
import Error from '@/views/Error.vue';
import ReproCatalogueDetails from '@/components/reproUser/ReproCatalogueDetails.vue';

Vue.use(VueRouter);

const imageSupplierCatalogueRoutes = [
  {
    path: '',
    component: CatalogueDashboard,
  },
  {
    path: ':catalogueKey',
    component: CatalogueDetails,
  },
  {
    path: ':catalogueKey/:routeCode',
    component: RouteCode,
  },
  {
    path: ':catalogueKey/:routeCode/:workPage',
    component: WorkPage,
  },
];

const pageSupplierCatalogueRoutes = [
  {
    path: '',
    component: CatalogueDashboard,
  },
  {
    path: ':catalogueKey',
    component: ReproCatalogueDetails,
  },
];

const routes = [
  {
    path: '/',
    name: 'App',
    component: App,
    meta: {
      isAuthRequired: false,
    },
    children: [
      {
        path: PRINT_USER_HOME_PATH,
        name: 'PrintUserHome',
        component: PrintUserHome,
        alias: `${PRINT_USER_HOME_PATH}&iss=*`,
        meta: {
          isAuthRequired: true,
          roles_allowed: [
            PRINT_USER_ROLE,
          ],
          errorRedirectionPath: '/',
        },
      },
      {
        path: PHOTOGRAPHER_HOME_PATH,
        component: AssetViewerHome,
        alias: `${PHOTOGRAPHER_HOME_PATH}&iss=*`,
        children: imageSupplierCatalogueRoutes,
        meta: {
          isAuthRequired: true,
          roles_allowed: [
            PHOTOGRAPHER_ROLE,
          ],
          errorRedirectionPath: '/',
        },
      },
      {
        path: REPRO_SERVICE_PROVIDER_HOME_PATH,
        component: AssetViewerHome,
        alias: `${REPRO_SERVICE_PROVIDER_HOME_PATH}&iss=*`,
        children: pageSupplierCatalogueRoutes,
        meta: {
          isAuthRequired: true,
          roles_allowed: [
            REPRO_SERVICE_PROVIDER_ROLE,
          ],
          errorRedirectionPath: '/',
        },
      },
      {
        path: '/error',
        name: 'Error',
        component: Error,
        meta: {
          isAuthRequired: false,
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthRequired = to.matched.some((route) => route?.meta?.isAuthRequired);
  if (isAuthRequired) {
    if (isUserAuthorised(to.matched)) {
      next();
    } else {
      const errorRecord = to.matched.find(
        (route) => route.meta.errorRedirectionPath,
      );
      const errorRedirectionPath = errorRecord ? errorRecord.meta.errorRedirectionPath : '/';
      next({ path: errorRedirectionPath });
    }
  } else {
    next();
  }
});

export default router;
