<template>
  <div class="upload-progress" :class="uploadProgressDynamicStyle">
    <div class="upload-progress-title upload-progress-spacing d-flex justify-space-between
    align-center">
      <p class="ma-0">{{ title }}</p>
      <div>
        <v-btn class="retry-button" @click="retryFailedUploads" v-if="uploadFailed" icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="retry-button-icon" color="white" v-bind="attrs" v-on="on">
                mdi-refresh
              </v-icon>
            </template>
            <span class="upload-progress-asset-name">Retry Failed Assets</span>
          </v-tooltip>
        </v-btn>
        <v-btn class="collapse-button" @click="handleCollapse" icon>
          <v-icon class="collapse-button-icon" color="white" v-if="isUploadProgressCollapsed">
            mdi-chevron-up
          </v-icon>
          <v-icon class="collapse-button-icon" color="white" v-else>mdi-chevron-down</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-if="showTimeLeft"
         class="upload-progress-subtitle px-6 d-flex justify-space-between align-center">
      <p class="ma-0">{{ estimatedTimeToUpload }}</p>
      <v-btn class="cancel-all-button" small text @click="handleCancelAllAssets">Cancel</v-btn>
    </div>
    <div v-if="!isUploadProgressCollapsed" class="upload-progress-assets">
      <div class="upload-progress-asset px-4 d-flex justify-space-between align-center"
           v-for="(asset, index) in getAssetsInFlight"
           :class="disabledAssetClass(asset)" :key="index">
        <span class="d-flex">
          <svg class="upload-progress-icon" role="img">
            <use xlink:href="@/assets/pl_icon_camera.svg#pl_icon_camera"/>
          </svg>
          <p class="upload-progress-asset-name text-wrap ml-4 mb-0">{{ asset.fileName }}</p>
        </span>
        <div v-if="asset.s3UploadStatus === assetUploadStatus.FAILED
              || asset.fileNameValidationStatus === fileNameValidationStatus.status.INVALID">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red"
                      v-bind="attrs"
                      v-on="on">
                mdi-close-octagon
              </v-icon>
            </template>
            <span data-test="s3-upload-status">
              {{ (asset.fileNameValidationStatus === fileNameValidationStatus.status.INVALID) ?
              `Validation Failed: ${asset.validationErrorMessage}`
              : $t('uploadFailureMessage.s3UploadFailure')}}</span>
          </v-tooltip>
        </div>
        <StatusIcon v-else-if="asset.s3UploadStatus === assetUploadStatus.DONE"
                    :status="'success'">
          <svg><use xlink:href="@/assets/pl_icon_checked_small.svg#pl_icon_checked_small"/>
          </svg>
        </StatusIcon>
        <v-progress-circular
          v-else-if="asset.s3UploadStatus === assetUploadStatus.IN_PROGRESS"
          :value="percentageUploaded(asset)"
          color="primary"
          :size="24"
        ></v-progress-circular>
        <v-icon
          @click="handleAssetCancel(asset)"
          color="black"
          class="cancel-button"
          v-if="asset.s3UploadStatus === assetUploadStatus.PENDING">
          mdi-close-circle
        </v-icon>
        <p class="cancelled-status mb-0"
           v-if="asset.s3UploadStatus === assetUploadStatus.CANCELLED">
          Cancelled
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StatusIcon from '@/components/StatusIcon.vue';
import {
  ASSET_UPLOAD_STATUS,
  FILE_NAME_VALIDATION_STATUS,
  SECONDS_EQUIVALENT,
} from '@/modules/constants';

const getTitle = (count, verb, status) => {
  const titleVerb = count > 1 ? `${verb}s` : verb;
  return `${count} ${titleVerb} ${status}`;
};

export default {
  name: 'UploadProgress',
  components: { StatusIcon },
  data() {
    return {
      assetUploadStatus: ASSET_UPLOAD_STATUS,
      fileNameValidationStatus: FILE_NAME_VALIDATION_STATUS,
    };
  },
  computed: {
    ...mapGetters([
      'getAssetsInFlight',
      'isUploadProgressCollapsed',
      'getSelectedWorkPage',
      'getAssetUploadProgress',
      'getCurrentUploadSpeed',
    ]),
    uploadProgressDynamicStyle() {
      let classes = '';
      if (this.getAssetsInFlight.length === 0) {
        classes += 'd-none ';
      }
      if (this.getSelectedWorkPage) {
        classes += 'upload-progress-work-page-bottom ';
      }
      return classes;
    },
    uploadsCompleted() {
      return this.getAssetsInFlight.filter(
        (asset) => asset.s3UploadStatus === ASSET_UPLOAD_STATUS.DONE,
      );
    },
    uploadsInProgress() {
      return this.getAssetsInFlight.filter(
        (asset) => asset.s3UploadStatus === ASSET_UPLOAD_STATUS.PENDING
          || asset.s3UploadStatus === ASSET_UPLOAD_STATUS.IN_PROGRESS,
      );
    },
    uploadCancelled() {
      return this.getAssetsInFlight.filter(
        (asset) => asset.s3UploadStatus === ASSET_UPLOAD_STATUS.CANCELLED,
      );
    },
    uploadFailed() {
      return this.getAssetsInFlight
        .some((asset) => asset.s3UploadStatus === ASSET_UPLOAD_STATUS.FAILED);
    },
    title() {
      if (this.uploadCancelled.length > 0) {
        return getTitle(this.uploadCancelled.length, 'upload', 'cancelled');
      }
      if (this.uploadsInProgress.length > 0) {
        return getTitle(this.uploadsInProgress.length, 'upload', 'in progress');
      }
      return getTitle(this.uploadsCompleted.length, 'upload', 'complete');
    },
    showTimeLeft() {
      return this.uploadsInProgress.length > 0 && !this.isUploadProgressCollapsed;
    },
    estimatedTimeToUpload() {
      const speedBps = this.getCurrentUploadSpeed;
      const assets = this.uploadsInProgress;
      let totalSizeToUpload = 0;
      let uploadedSize = 0;
      assets.forEach((asset) => {
        totalSizeToUpload += asset.totalSize;
        uploadedSize += asset.uploadProgress;
      });
      if (speedBps === 0) {
        return 'Calculating time to upload';
      }
      const secondsLeft = ((totalSizeToUpload - uploadedSize) * 8) / speedBps;
      return this.getTimeLeft(secondsLeft);
    },
  },
  methods: {
    handleAssetCancel(asset) {
      this.$store.commit('updateAssetInFlight', {
        catalogueKey: asset.catalogueKey,
        routeCode: asset.routeCode,
        workPageNumber: asset.workPageNumber,
        fileName: asset.fileName,
        uploadProgress: asset.uploadProgress,
        totalSize: asset.totalSize,
        s3UploadStatus: ASSET_UPLOAD_STATUS.CANCELLED,
      });
    },
    handleCancelAllAssets() {
      this.$store.commit('updateAssetsInFlight');
    },
    retryFailedUploads() {
      const failedAssets = this.getAssetsInFlight
        .filter((asset) => asset.s3UploadStatus === ASSET_UPLOAD_STATUS.FAILED);
      this.$store.commit('removeAssetsInFlight', failedAssets);
      // eslint-disable-next-line no-restricted-syntax
      for (const asset of failedAssets) {
        const { file, type } = asset;
        this.$store.dispatch('uploadAssets', {
          files: [file], type,
        });
      }
    },
    handleCollapse() {
      this.$store.commit('toggleUploadProgressCollapse');
    },
    disabledAssetClass(asset) {
      return asset.s3UploadStatus === ASSET_UPLOAD_STATUS.PENDING ? 'upload-progress-disabled-asset' : '';
    },
    percentageUploaded(asset) {
      return (asset.uploadProgress / asset.totalSize) * 100;
    },
    getTimeLeft(secondsLeft) {
      if (secondsLeft < 60) {
        return this.$t('estimatedTimeMessages.seconds');
      }
      if (secondsLeft >= SECONDS_EQUIVALENT.DAY) {
        return this.$t('estimatedTimeMessages.others', {
          timeLeft: `${Math.round(secondsLeft / SECONDS_EQUIVALENT.DAY)} day`,
        });
      }
      if (secondsLeft >= SECONDS_EQUIVALENT.HOUR) {
        return this.$t('estimatedTimeMessages.others', {
          timeLeft: `${Math.round(secondsLeft / SECONDS_EQUIVALENT.HOUR)} hour`,
        });
      }
      return this.$t('estimatedTimeMessages.others', {
        timeLeft: `${Math.round(secondsLeft / SECONDS_EQUIVALENT.MINUTE)} min`,
      });
    },
  },
  created() {
    window.addEventListener('beforeunload', (e) => {
      if (this.uploadsInProgress.length === 0) {
        return;
      }
      e.preventDefault();
      e.returnValue = '';
    });
  },
};
</script>

<style scoped lang="scss">
.upload-progress-spacing {
    padding-right: 12px!important;
    padding-left: 24px!important;
}
.upload-progress {
  position: fixed;
  bottom: 0;
  right: 64px;
  background-color: $pl_color-white100;
  width: 500px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.upload-progress-work-page-bottom {
  bottom: 0px !important;
}

.upload-progress-title {
  background-color: $black50;
  color: $pl_color-white100;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 54px;
}

.upload-progress-subtitle {
  background-color: $grey100;
  color: $pl_color-black100;
  height: 24px;
  font-size: 14px;
}

.upload-progress-assets {
  max-height: 300px;
  overflow: auto;
};

.upload-progress-asset {
  border-bottom: solid 1px $grey50;
  font-size: 14px;
  display: block;
  overflow: auto;
  padding: 13px;
}

.upload-progress-asset-name {
  max-width: 350px;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  line-height: 24px;
  white-space: nowrap;
}

.upload-progress-icon {
  height: 24px;
  width: 24px;
}

.upload-progress-disabled-asset {
  opacity: 0.5;
}

.collapse-button.v-btn {
  background: $black50;
  border-radius: 16px;
  width: 32px;
  height: 32px;
}

.collapse-button-icon.v-icon {
  width: 32px;
  height: 32px;
  font-size: 32px;
}

.retry-button-icon.v-icon {
  font-size: 25px;
}
</style>
