export const redirectIfNotEqualsCurrentPath = (router, path) => {
  if (router && router.push && path) {
    const {
      currentRoute: { path: curPath },
    } = router;
    if (curPath !== path) router.push(path);
  }
};

export const rules = {
  required: (value) => !!value || 'Required',
  length: (number) => (value) => value?.length === number || `Required length is ${number}`,
  comparator: (operator, limit) => (value) => {
    const parsedLimit = parseInt(limit, 10);
    const parsedValue = parseInt(value, 10);
    if (!Number.isNaN(parsedLimit) && !Number.isNaN(parsedValue)) {
      if (operator === 'gte') {
        return parsedValue >= parsedLimit || `Should be greater than or equal to ${parsedLimit}`;
      }
      if (operator === 'lte') {
        return parsedValue <= parsedLimit || `Should be less than or equal to ${parsedLimit}`;
      }
    }
    return true;
  },
  range: (min, max) => (value) => {
    let errorMessage;
    if (max) {
      errorMessage = `Required length is ${min} - ${max}`;
      return (value?.length >= min && value?.length <= max) || errorMessage;
    }
    errorMessage = `Minimum length is ${min}`;
    return value?.length >= min || errorMessage;
  },
  numbers: (value) => /^\d+$/.test(value) || 'Only numerics (0-9) allowed',
  alphabets: (value) => /^[A-Za-z]+$/.test(value) || 'Only alphabets (a-zA-Z) allowed',
  alphaNumerics: (value) => /^[A-Za-z0-9]+$/.test(value) || 'Only alphanumerics (a-z/A-Z/0-9) are allowed',
};

export default redirectIfNotEqualsCurrentPath;
