import { createCatalogue, deleteCatalogue, getCatalogues } from '@/modules/clients/catalogueClient';
import { getServiceProviders } from '@/modules/clients/serviceProviderClient';
import { isAssetCancelled } from '@/modules/assetSelection';
import getWorkPages, {
  fetchWorkPageAssets,
  createWorkPages,
  fetchCatalogueAssets,
} from '@/modules/clients/workPageClient';
import {
  getWorkPageAssetThumbnails,
  validateAssetFilename,
  getCatalogueAssetThumbnails,
} from '@/modules/clients/workPageAssetsClient';
import {
  ASSET_UPLOAD_STATUS,
  STATE,
  ASSET_OPERATION_TYPE, FILE_NAME_VALIDATION_STATUS, ASSET_ITEM_RETRIEVAL_LIMIT, ASSET_TYPE,
} from '@/modules/constants';
// eslint-disable-next-line import/named
import {
  extractCatalogueDetailsForDesignDoc,
  extractCatalogueDetailsForImage,
  uploadAssetAndUpdateState,
} from '@/modules/actionUtils';

export default {
  async createCatalogue({ commit }, { catalogueInfo }) {
    commit('setErrorStatus', { name: STATE.CREATE_CATALOGUES, status: false });
    commit('setLoadingStatus', { name: STATE.CREATE_CATALOGUES, status: true });
    const { success, catalog } = await createCatalogue(catalogueInfo);
    if (!success) {
      commit('setErrorStatus', { name: STATE.CREATE_CATALOGUES, status: true });
    } else {
      commit('addNewCatalogue', catalog);
      commit('setSelectedCatalogueKey', catalog.CatalogueKey);
    }
    commit('setLoadingStatus', { name: STATE.CREATE_CATALOGUES, status: false });
  },

  async deleteCatalogue({ commit }, catalogueKey) {
    commit('setErrorStatus', { name: STATE.CREATE_CATALOGUES, status: false });
    commit('setLoadingStatus', { name: STATE.CREATE_CATALOGUES, status: true });
    const { success } = await deleteCatalogue(catalogueKey);
    if (!success) {
      commit('setCatalogueHasAssets', true);
    } else {
      commit('removeCatalogue', catalogueKey);
      commit('setSelectedCatalogueKey', '');
    }
    commit('setLoadingStatus', { name: STATE.CREATE_CATALOGUES, status: false });
  },

  async setCatalogueDashboard({ commit }, payload) {
    const all = payload?.all || false;
    commit('setLoadingStatus', { name: STATE.CATALOGUES, status: true });
    const data = await getCatalogues(all);
    commit('setCatalogues', data);
    commit('setLoadingStatus', { name: STATE.CATALOGUES, status: false });
  },

  async fetchServiceProviders({ commit }) {
    const serviceProviders = await getServiceProviders();
    commit('setServiceProviders', serviceProviders);
  },

  toggleCollapseStatus({ commit, getters }, { catalogueHierarchyElement }) {
    const currentStatus = getters.getCollapseStatus(catalogueHierarchyElement);
    commit('setCollapseStatus', { catalogueHierarchyElement, status: !currentStatus });
  },

  async fetchWorkPages({ commit }, { catalogueKey }) {
    commit('setLoadingStatus', { name: STATE.WORK_PAGES, status: true });
    commit('setErrorStatus', { name: STATE.WORK_PAGES, status: false });
    const catalogueData = await getWorkPages(catalogueKey);
    if (catalogueData === null) {
      commit('setErrorStatus', { name: STATE.WORK_PAGES, status: true });
    }
    commit('setCatalogueData', { catalogueKey, catalogueData });
    commit('setLoadingStatus', { name: STATE.WORK_PAGES, status: false });
  },

  async fetchCatalogueAssets({ commit }, { catalogueKey, type }) {
    commit('setLoadingStatus', { name: STATE.WORK_PAGES, status: true });
    commit('setErrorStatus', { name: STATE.WORK_PAGES, status: false });
    commit('setLoadingStatus', { name: STATE.FETCH_ASSETS, status: true });
    let counter = 0;
    const limit = ASSET_ITEM_RETRIEVAL_LIMIT;
    let endKey;
    while ((!endKey && counter === 0) || (endKey && counter > 0)) {
      // eslint-disable-next-line no-await-in-loop
      const { catalogueData, lastEvaluatedKey } = await fetchCatalogueAssets(
        catalogueKey,
        type,
        limit,
        endKey,
      );
      if (catalogueData === null) {
        commit('setErrorStatus', {
          name: STATE.WORK_PAGES,
          status: true,
        });
      } else {
        commit('addAssetsInCatalogue', {
          catalogueKey,
          catalogueData,
        });
        commit('addAssetThumbnails', catalogueData);
      }
      commit('setLoadingStatus', {
        name: STATE.WORK_PAGES,
        status: false,
      });
      endKey = lastEvaluatedKey;
      counter += 1;
    }
    commit('setLoadingStatus', { name: STATE.FETCH_ASSETS, status: false });
  },

  async uploadAssets({ commit, dispatch, getters }, payload) {
    const { files, type } = payload;
    const assets = [];
    commit('setLastRecordedTime', new Date().getTime());

    for (let fileIndex = files.length - 1; fileIndex >= 0; fileIndex -= 1) {
      const fileName = files[fileIndex].name;
      const {
        catalogueKey, routeCode, workPageNumber, metadata,
      } = (type === ASSET_TYPE.DESIGN_DOC)
        ? extractCatalogueDetailsForDesignDoc(fileName)
        : extractCatalogueDetailsForImage(fileName);

      commit('addAssetInFlight', {
        catalogueKey,
        routeCode,
        workPageNumber,
        fileName,
        file: files[fileIndex],
        metadata,
        type,
        uploadProgress: 0,
        totalSize: files[fileIndex].size,
        s3UploadStatus: ASSET_UPLOAD_STATUS.PENDING,
        [FILE_NAME_VALIDATION_STATUS.name]: FILE_NAME_VALIDATION_STATUS.status.PENDING,
      });
      assets.push({
        catalogueKey,
        routeCode,
        workPageNumber,
        fileName,
        metadata,
      });
    }

    for (let assetIndex = assets.length - 1, fileIndex = 0; assetIndex >= 0;
      assetIndex -= 1, fileIndex += 1) {
      /* eslint-disable no-await-in-loop */
      const {
        catalogueKey, workPageNumber, routeCode, fileName, metadata,
      } = assets[assetIndex];
      const assetsInFlight = getters.getAssetsInFlight;
      const assetInfo = {
        fileName,
        catalogueKey,
        workPageNumber,
        routeCode,
      };
      if (!isAssetCancelled(assetInfo, assetsInFlight)) {
        const { data, message } = await validateAssetFilename({
          catalogueKey,
          routeCode,
          workPageNumber,
          fileName,
          type,
          metadata,
        });
        const isValidFileName = !!data?.isValidFileName;
        const validationErrorMessage = message || '';
        commit('updateAssetInFlight', {
          catalogueKey,
          routeCode,
          workPageNumber,
          fileName,
          file: files[fileIndex],
          metadata,
          type,
          uploadProgress: 0,
          totalSize: files[fileIndex].size,
          s3UploadStatus: isValidFileName ? ASSET_UPLOAD_STATUS.PENDING
            : ASSET_UPLOAD_STATUS.FAILED,
          [FILE_NAME_VALIDATION_STATUS.name]: isValidFileName
            ? FILE_NAME_VALIDATION_STATUS.status.VALID
            : FILE_NAME_VALIDATION_STATUS.status.INVALID,
          validationErrorMessage,
        });
        const fileSize = files[fileIndex].size;
        if (isValidFileName) {
          const assetDetails = {
            catalogueKey,
            routeCode,
            workPageNumber,
            fileName,
            files,
            fileIndex,
            fileSize,
            metadata,
            type,
            validationErrorMessage,
          };
          await uploadAssetAndUpdateState({
            commit,
            dispatch,
          }, assetDetails);
        }
      }
    }
    commit('resetUploadData');
  },

  updateAssetsForPageSupplier({ commit }, payload) {
    const {
      catalogueKey, operation, asset,
    } = payload;
    if (operation === ASSET_OPERATION_TYPE.THUMBNAIL_UPDATE) {
      commit('setAssetThumbnail', asset);
    }
    if (operation === ASSET_OPERATION_TYPE.UPDATE) {
      commit('updateAssetInCatalogue', {
        catalogueKey,
        assetUpdate: asset,
      });
    }
    if (operation === ASSET_OPERATION_TYPE.DELETE) {
      commit('removeAssetFromCatalogue', {
        catalogueKey,
        fileName: asset.fileName,
      });
    }
    if (operation === ASSET_OPERATION_TYPE.ADD) {
      commit('addAssetToCatalogue', {
        catalogueKey,
        asset,
      });
    }
  },

  updateAssets({ commit }, payload) {
    const {
      catalogueKey, routeCode, workPageNumber, operation, asset,
    } = payload;
    if (operation === ASSET_OPERATION_TYPE.THUMBNAIL_UPDATE) {
      commit('setAssetThumbnail', asset);
    }
    if (operation === ASSET_OPERATION_TYPE.UPDATE) {
      commit('updateAssetInWorkPage', {
        catalogueKey,
        routeCode,
        workPageNumber,
        assetUpdate: asset,
      });
    }
    if (operation === ASSET_OPERATION_TYPE.DELETE) {
      commit('removeAssetFromWorkPage', {
        catalogueKey,
        routeCode,
        workPageNumber,
        fileName: asset.fileName,
      });
    }
    if (operation === ASSET_OPERATION_TYPE.ADD) {
      commit('addAssetToWorkPage', {
        catalogueKey,
        routeCode,
        workPageNumber,
        asset,
      });
    }
  },

  async fetchWorkPageAssets({ commit, dispatch }, payload) {
    const {
      catalogueKey, routeCode, workPage, type,
    } = payload;
    const limit = ASSET_ITEM_RETRIEVAL_LIMIT;
    if (catalogueKey && routeCode && workPage) {
      commit('setLoadingStatus', { name: STATE.WORK_PAGE_ASSETS, status: true });
      commit('setErrorStatus', { name: STATE.WORK_PAGE_ASSETS, status: false });
      commit('setLoadingStatus', { name: STATE.FETCH_ASSETS, status: true });
      await dispatch('fetchWorkPages', { catalogueKey });
      let counter = 0;
      let endKey;
      while ((!endKey && counter === 0) || (endKey && counter > 0)) {
        const { assets, lastEvaluatedKey } = await fetchWorkPageAssets(catalogueKey,
          routeCode, workPage, type, limit, endKey);
        if (assets === null) {
          commit('setErrorStatus', { name: STATE.WORK_PAGE_ASSETS, status: true });
        } else {
          commit('addAssetsToWorkPage', { ...payload, assets });
          commit('addAssetThumbnails', assets);
        }
        commit('setLoadingStatus', { name: STATE.WORK_PAGE_ASSETS, status: false });
        endKey = lastEvaluatedKey;
        counter += 1;
      }
      commit('setLoadingStatus', { name: STATE.FETCH_ASSETS, status: false });
    }
  },

  initKeycloak({ commit }, keycloak) {
    commit('initKeycloak', keycloak);
    localStorage.setItem('refresh_token', keycloak.refreshToken);
    localStorage.setItem('access_token', keycloak.token);
  },

  initWebsocket({ commit }, websocket) {
    commit('setWebSocketConnection', websocket);
  },

  webSocketSendMessage({ state, commit }, message) {
    if (state.webSocketConnection.readyState !== 1) {
      commit('setWebSocketMessages', message);
    } else {
      state.webSocketConnection.send(JSON.stringify(message));
    }
  },

  async createWorkPage({ commit }, payload) {
    commit('setLoadingStatus', { name: STATE.CREATE_WORK_PAGES, status: true });
    commit('setErrorStatus', { name: STATE.CREATE_WORK_PAGES, status: false });
    const { catalogueKey } = payload;
    const response = await createWorkPages(payload);
    const { success, data, catalogueUpdatedAt } = response;
    if (!success) {
      commit('setErrorStatus', { name: STATE.CREATE_WORK_PAGES, status: true });
    } else {
      commit('setCatalogueData', { catalogueKey, catalogueData: data });
      commit('updateCatalogueModificationTime', { catalogueKey, catalogueUpdatedAt });
    }
    commit('setLoadingStatus', { name: STATE.CREATE_WORK_PAGES, status: false });
  },

  calculateUploadSpeed({ commit, getters }) {
    const currentTime = new Date().getTime();
    const duration = (currentTime - getters.getLastRecordedTime) / 1000;
    if (duration > getters.getConnectionSpeedWindow) {
      let uploadedSize = 0;
      getters.getAssetsInFlight.forEach((asset) => {
        uploadedSize += asset.uploadProgress;
      });
      const bytesUploadedInLastInterval = getters.getTotalBytesUploaded;
      const bitsLoaded = (uploadedSize - bytesUploadedInLastInterval) * 8;
      const speedBps = parseFloat((bitsLoaded / duration).toFixed(2));
      commit('setUploadSpeed', speedBps);
      commit('setLastRecordedTime', currentTime);
      commit('setTotalBytesUploaded', uploadedSize);
      commit('increaseConnectionSpeedWindow');
    }
  },

  async getWorkPageThumbnails({ commit }, payload) {
    const {
      catalogueKey, routeCode, workPageNumber, type,
    } = payload;
    const { success, data } = await getWorkPageAssetThumbnails(catalogueKey,
      routeCode, workPageNumber, type);
    if (success) {
      commit('setAssetThumbnails', data);
    }
  },

  async getCatalogueThumbnails({ commit }, payload) {
    const {
      catalogueKey, type,
    } = payload;
    const { success, data } = await getCatalogueAssetThumbnails(catalogueKey, type);
    if (success) {
      commit('setAssetThumbnails', data);
    }
  },
};
