import axios from 'axios';
import { SERVICE_PROVIDER_ENDPOINT } from '@/modules/constants';

const {
  VUE_APP_GET_ALL_CATALOGUES_API,
} = process.env;

export const getServiceProviders = async () => {
  const headers = {
    authorization: `Bearer ${localStorage.getItem('access_token')}`,
  };
  try {
    const receivedResponse = await axios.get(`${VUE_APP_GET_ALL_CATALOGUES_API}${SERVICE_PROVIDER_ENDPOINT}`, {
      headers,
    });
    return receivedResponse.data.serviceProviders;
  } catch (error) {
    console.log('Error fetching service providers', error);
    return [];
  }
};

export default getServiceProviders;
