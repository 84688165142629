import { ASSET_UPLOAD_STATUS } from '@/modules/constants';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      fileNameConflict: false,
      fileNameConflictDialogProps: {
        title: 'Error',
        message: 'One or more images have file names conflicting with the ones that are already uploaded. Please rename and try again.',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getAssetsInFlight',
    ]),
  },
  methods: {
    checkFileNameConflict(fileName) {
      return this.getAssetsInFlight.some((asset) => asset.fileName === fileName
        && (asset.s3UploadStatus === ASSET_UPLOAD_STATUS.PENDING
        || asset.s3UploadStatus === ASSET_UPLOAD_STATUS.IN_PROGRESS
        || asset.s3UploadStatus === ASSET_UPLOAD_STATUS.DONE));
    },
  },
};
