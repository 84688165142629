export default {
  en: {
    applicationName: 'Catalogue Production Tool',
    catalogueDataFields: {
      catalogueKey: 'Catalogue Key',
      catalogueName: 'Catalogue Name',
      company: 'Company',
      catalogueType: 'Catalogue Type',
      seasonId: 'Season ID',
      seasonName: 'Season Name',
      catalogueVersion: 'Catalogue Version',
      year: 'Year',
      createdBy: 'Created By',
      updatedAt: 'Updated At',
      createdAt: 'Created At',
      emailId: 'Email ID',
      formFields: {
        companyId: 'Company ID',
        companyName: 'Company Name',
        title: 'Catalogue Title',
        type: 'Type',
        version: 'Version',
      },
    },
    routeCodeData: {
      routeCode: 'Route Code',
      workPageFrom: 'From',
      workPageTo: 'To',
      serviceProvider: 'MDL',
    },
    assetName: {
      image: 'files',
      designDoc: 'files',
    },
    assetData: {
      figureNumber: 'Figure Number',
      articleNumbers: 'Article Numbers',
      pagina: 'Pagina',
      status: 'Status',
      sharedienSyncDate: 'SAM Sync Date',
    },
    assetStatus: {
      metadata: {
        pending: 'Assign Metadata Pending',
        done: 'Assign Metadata Success',
      },
      sync: {
        pending: 'SAM Sync Pending',
        done: 'SAM Sync Success',
        failed: 'SAM Sync Failed',
      },
    },
    title: {
      catalogue: 'Catalogues',
      error: 'Error',
      renameAssetsConfirmation: 'Rename {assetType}?',
      syncAssetsConfirmation: 'Upload to SAM?',
      deleteAssetsConfirmation: 'Delete {assetType}?',
      metadata: 'Catalogue Metadata',
      structure: 'Catalogue Structure',
      catalogueForm: 'Catalogue Creation',
      createCatalogueConfirmation: 'Create new Catalogue?',
      deleteCatalogueConfirmation: 'Delete Catalogue?',
      catalogueHasAssets: 'Catalogue Already Has Assets',
    },
    actions: {
      logOut: 'Log out',
      assignMetadata: 'Assign Metadata',
      updateMetadata: 'Update metadata',
      rename: 'Rename',
      sync: 'Sync',
      delete: 'Delete',
      upload: 'Upload files',
      ok: 'Okay',
      cancel: 'Cancel',
      viewInSam: 'View in SAM',
      selectAll: 'Select all',
      createCatalogue: 'Create Catalogue',
      search: 'Search',
      updateStructure: 'Update structure',
      uploadXml: 'Upload XML',
      clear: 'Clear',
      submit: 'Submit',
    },
    process: {
      uploadStep: 'Select and upload',
      step: 'Step',
    },
    assetViewerTableHeaders: {
      preview: 'Preview',
      assetName: 'Asset Name',
      assetStatus: 'Asset Status',
      uploadedAt: 'Uploaded At',
    },
    message: {
      dashboard: 'Please select one catalogue to upload {assetType}.',
      catalogue: 'Please select one of the route code to view the working pages.',
      routeCode: 'You have to upload {assetType} on these pages. Please select one to upload {assetType}.',
      uploadBox: {
        dragMessage: 'Drag {assetType} here',
        orMessage: 'or',
        clickButtonMessage: 'Click the {buttonName} button',
      },
      catalogueForm: 'Enter the following details to create a new catalogue.',
      renameNote: 'Note: {assetType} will be renamed and synced automatically once metadata is assigned',
    },
    errorMessages: {
      assignMetadata: 'Some or all {assetType} had an error with updating metadata.',
      renameAssets: 'Some or all {assetType} had an error while renaming.',
      syncAssets: 'Syncing {assetType} failed. Please retry.',
      deleteAssets: 'Deleting {assetType} failed. Please retry.',
      createWorkPages: 'Creation of one or more Work Pages failed. Please check and try again.',
      createCatalogue: 'Catalogue creation failed. Please try again.',
      catalogueHasAssets: 'This Catalogue already have assets uploaded, So cannot be deleted',
    },
    confirmationMessages: {
      renameAssets: 'Are you sure you want to rename these {assetType}?',
      syncAssets: 'You are about to upload the selected {assetType} to SAM. Are you sure?',
      deleteAssets: 'You are about to permanently delete the selected {assetType}. Are you sure?',
      createCatalogue: 'You are about to create a new Catalogue. Are you sure?',
      deleteCatalogue: 'You are about to delete this Catalogue. Are you sure?',
    },
    estimatedTimeMessages: {
      seconds: 'Less than a minute left',
      others: 'Expected to complete in {timeLeft}',
    },
    uploadFailureMessage: {
      s3UploadFailure: 'Upload Failed',
      validationFailure: 'Validation Failed',
    },
    userGuide: {
      label: 'User Guide',
      printUserGuide: [
        {
          content: 'If you click Create Catalogue, a dialog is opened where you can manually create a catalogue.',
          subtitle: 'Create Catalogue',
        },
        {
          content: 'You will be asked to provide certain metadata that are related to the DTP guideline. Then click on Submit.',
          subtitle: 'Create Catalogue',
        },
        {
          content: 'After clicking Okay the new catalogue node is added to the list and you will be forwarded to the detail page where you can add pages.',
          subtitle: 'Create Catalogue',
        },
        {
          content: 'You will see the general information of a catalogue in the upper part of the view.',
          subtitle: 'Catalogue Structure - Detail View',
        },
        {
          content: 'To add new pages, you will be asked for additional metadata such as Catalogue Key, Route Code, Range from to and a related service provider. Conventions should follow the DTP guideline. After entering the details, click on Update Structure',
          subtitle: 'Catalogue Structure - Detail View',
        },
        {
          content: 'You will see the updated pages of the catalogue',
          subtitle: 'Catalogue Structure - Detail View',
        },
        {
          content: 'In comparison to the manual creation of a catalogue, you can use XML files to create the structure for you automatically.',
          subtitle: 'Upload XML',
        },
        {
          content: 'Just browse to the relevant file on your local computer and in your file system to upload it to CPT.',
          subtitle: 'Upload XML',
        },
        {
          content: 'Click on okay to create catalogue',
          subtitle: 'Upload XML',
        },
        {
          content: 'If the structure in the XML is correct, the catalogue node is created automatically.',
          subtitle: 'Upload XML',
        },
        {
          content: 'Click on Delete button to delete the catalogue. A confirmation dialog will be shown.',
          subtitle: 'Delete Catalogue',
        },
        {
          content: 'On clicking okay in deletion confirmation dialog, catalogue and corresponding workpages will be deleted if the catalogue has no assets in it.',
          subtitle: 'Delete Catalogue',
        },
        {
          content: 'If the catalogue has assets, deletion will not happen and the same message will be displayed to the user.',
          subtitle: 'Delete Catalogue',
        },
      ],
      photographerGuide: [
        {
          content: 'Click on Upload Files button',
          subtitle: 'Upload',
        },
        {
          content: 'On clicking Upload Files button, you can choose renamed files from your local computer and upload',
          subtitle: 'Upload',
        },
        {
          content: 'While the upload is in progress, you will see a dialog box on the right that shows the current status and the remaining time till all files are provided in our tool. The upload time depends on many factors such as network speed, location, firewall access, background processes, multiple users in the network etc. To speed up the process, try to optimize one or all of those factors. While the upload is in progress, you can select other catalogues and start the process there as well. Uploads are handled in a queue are processed securely step by step. As long as you have opened your browser, the uploading is in progress and can be finished without you have to control it.',
          subtitle: 'Upload',
        },
        {
          content: 'If there is any validation issue in the naming of assets, it will displayed on hovering the cross mark and give better insights',
          subtitle: 'Upload',
        },
        {
          content: 'In the overview, you can choose one of our current catalogues according to its high level naming conventions such as catalogue key e.g. OV8956C. Just click on one item and you will see the next navigation level.',
          subtitle: 'Catalogue Selection',
        },
        {
          content: 'After selecting the catalogue, you can choose a route code that is related to this unique catalogue. Again, just click on an item and you will see the next navigation level.',
          subtitle: 'Route Code Selection',
        },
        {
          content: 'The last level for your selection is the page. Choose a page, where you want to view your uploaded assets.',
          subtitle: 'Page Selection',
        },
        {
          content: 'Once the upload is completed, assign metadata process will be automatically completed',
          subtitle: 'Assign Metadata',
        },
        {
          content: 'Once the assign metadata is completed, sync to SAM will be automatically completed',
          subtitle: 'Sync',
        },
        {
          content: 'On clicking on View in SAM, you will be redirected to SAM',
          subtitle: 'SAM',
        },
        {
          content: 'You can view the synced files in SAM.',
          subtitle: 'SAM',
        },
        {
          content: 'To directly see the full filename, all related metadata, the timestamp for the sync to SAM and the asset status, click on list view button.',
          subtitle: 'List view',
        },
        {
          content: 'The list view is focused on additional information of an asset. Also there is an integrated search that helps you in filtering assets according to your inputs. The search is performed on the filename and metadata fields of the list table. By default the list shows 50 entries. You can adjust this value to your needs. Depending on your input, there will be pagination of the list as well.',
          subtitle: 'List view',
        },
      ],
      rsProviderGuide: [
        {
          content: 'Click on Upload Files button',
          subtitle: 'Upload',
        },
        {
          content: 'On clicking Upload Files button, you can choose renamed files from your local computer and upload',
          subtitle: 'Upload',
        },
        {
          content: 'While the upload is in progress, you will see a dialog box on the right that shows the current status and the remaining time till all files are provided in our tool. The upload time depends on many factors such as network speed, location, firewall access, background processes, multiple users in the network etc. To speed up the process, try to optimize one or all of those factors. While the upload is in progress, you can select other catalogues and start the process there as well. Uploads are handled in a queue are processed securely step by step. As long as you have opened your browser, the uploading is in progress and can be finished without you have to control it.',
          subtitle: 'Upload',
        },
        {
          content: 'If there is any validation issue in the naming of assets, it will displayed on hovering the cross mark and give better insights',
          subtitle: 'Upload',
        },
        {
          content: 'In the overview, you can choose one of our current catalogues according to its high level naming conventions such as catalogue key e.g. OVAS33C . Just click on one item and you will see the uploaded assets.',
          subtitle: 'Catalogue Selection',
        },
        {
          content: 'Once the upload is completed, assign metadata process will be automatically completed',
          subtitle: 'Assign Metadata',
        },
        {
          content: 'Once the assign metadata is completed, sync to SAM will be automatically completed',
          subtitle: 'Sync',
        },
        {
          content: 'On clicking on View in SAM, you will be redirected to SAM',
          subtitle: 'SAM',
        },
        {
          content: 'You can view the synced files in SAM.',
          subtitle: 'SAM',
        },
        {
          content: 'To directly see the full filename, all related metadata, the timestamp for the sync to SAM and the asset status, click on list view button.',
          subtitle: 'List view',
        },
        {
          content: 'The list view is focused on additional information of an asset. Also there is an integrated search that helps you in filtering assets according to your inputs. The search is performed on the filename and metadata fields of the list table. By default the list shows 50 entries. You can adjust this value to your needs. Depending on your input, there will be pagination of the list as well.',
          subtitle: 'List view',
        },
      ],
    },
  },
  de: {
    applicationName: 'Catalogue Production Tool',
    catalogueDataFields: {
      catalogueKey: 'Catalogue Key',
      catalogueName: 'Catalogue Name',
      company: 'Company',
      catalogueType: 'Catalogue Type',
      seasonId: 'Season ID',
      seasonName: 'Season Name',
      catalogueVersion: 'Catalogue Version',
      year: 'Year',
      createdBy: 'Created By',
      updatedAt: 'Updated At',
      createdAt: 'Created At',
      emailId: 'Email ID',
      formFields: {
        companyId: 'Company ID',
        companyName: 'Company Name',
        title: 'Catalogue Title',
        type: 'Type',
        version: 'Version',
      },
    },
    routeCodeData: {
      routeCode: 'Route Code',
      workPageFrom: 'From',
      workPageTo: 'To',
      serviceProvider: 'MDL',
    },
    assetName: {
      image: 'Dateien',
      designDoc: 'Dateien',
    },
    assetData: {
      figureNumber: 'Figure Number',
      articleNumbers: 'Article Numbers',
      pagina: 'Pagina',
      status: 'Status',
      sharedienSyncDate: 'SAM Sync Date',
    },
    assetStatus: {
      metadata: {
        pending: 'Assign Metadata Pending',
        done: 'Assign Metadata Success',
      },
      sync: {
        pending: 'SAM Sync Pending',
        done: 'SAM Sync Success',
        failed: 'SAM Sync Failed',
      },
    },
    title: {
      catalogue: 'Kataloge',
      error: 'Error',
      renameAssetsConfirmation: '{assetType} umbenennen?',
      syncAssetsConfirmation: 'Auf SAM hochladen?',
      deleteAssetsConfirmation: '{assetType} löschen?',
      metadata: 'Catalogue Metadata',
      structure: 'Catalogue Structure',
      catalogueForm: 'Catalogue Creation',
      createCatalogueConfirmation: 'Create new Catalogue?',
      deleteCatalogueConfirmation: 'Delete Catalogue?',
      catalogueHasAssets: 'Catalogue Already Has Assets',
    },
    actions: {
      logOut: 'Ausloggen',
      assignMetadata: 'Assign Metadata',
      updateMetadata: 'Update metadata',
      rename: 'Rename',
      sync: 'Sync',
      delete: 'Delete',
      upload: 'Upload files',
      ok: 'Okay',
      cancel: 'Cancel',
      viewInSam: 'View in SAM',
      selectAll: 'Select all',
      createCatalogue: 'Create Catalogue',
      search: 'Search',
      updateStructure: 'Update structure',
      uploadXml: 'Upload XML',
      clear: 'Clear',
      submit: 'Submit',
    },
    process: {
      uploadStep: 'Select and upload',
      step: 'Step',
    },
    assetViewerTableHeaders: {
      preview: 'Preview',
      assetName: 'Asset Name',
      assetStatus: 'Asset Status',
      uploadedAt: 'Uploaded At',
    },
    message: {
      dashboard: 'Bitte wähle einen Katalog aus, um {assetType} hochzuladen.',
      catalogue: 'Please select one of the route code to view the working pages.',
      routeCode: 'Bitte wähle eine Strecke aus, um {assetType} hochzuladen.',
      uploadBox: {
        dragMessage: 'Drag {assetType} here',
        orMessage: 'or',
        clickButtonMessage: 'Click the {buttonName} button',
      },
      catalogueForm: 'Enter the following details to create a new catalogue.',
      renameNote: 'Note: {assetType} will be renamed and synced automatically once metadata is assigned',
    },
    errorMessages: {
      assignMetadata: 'Bei einigen oder allen {assetType} ist beim Aktualisieren der Metadaten ein Fehler aufgetreten. Bitte versuche es erneut.',
      renameAssets: 'Bei einigen oder allen {assetType} ist beim Umbenennen ein Fehler aufgetreten. Bitte versuche es erneut.',
      syncAssets: 'Bei einigen oder allen {assetType} ist beim Synchronisieren ein Fehler aufgetreten. Bitte versuche es erneut.',
      deleteAssets: 'Bei einigen oder allen {assetType} ist beim Löschen ein Fehler aufgetreten. Bitte versuche es erneut.',
      createWorkPages: 'Creation of one or more Work Pages failed. Please check and try again.',
      createCatalogue: 'Catalogue creation failed. Please try again.',
      catalogueHasAssets: 'This Catalogue already have assets uploaded, So cannot be deleted',
    },
    confirmationMessages: {
      renameAssets: 'Möchtest du diese {assetType} wirklich umbenennen?',
      syncAssets: 'Möchtest du die ausgewählten {assetType} auf SAM hochzuladen?',
      deleteAssets: 'Möchtest du die ausgewählten {assetType} endgültig löschen?',
      createCatalogue: 'You are about to create a new Catalogue. Are you sure?',
      deleteCatalogue: 'You are about to delete this Catalogue. Are you sure?',
    },
    estimatedTimeMessages: {
      seconds: 'Less than a minute left',
      others: 'Expected to complete in {timeLeft}',
    },
    uploadFailureMessage: {
      s3UploadFailure: 'Upload Failed',
      validationFailure: 'Validation Failed',
    },
    userGuide: {
      label: 'Benutzerhandbuch',
      printUserGuide: [
        {
          content: 'Wenn Sie auf Katalog erstellen klicken, wird ein Dialog geöffnet, in dem Sie manuell einen Katalog erstellen können.',
          subtitle: 'Katalog erstellen',
        },
        {
          content: 'Sie werden aufgefordert, bestimmte Metadaten anzugeben, die sich auf die DTP-Richtlinie beziehen. Klicken Sie dann auf Senden.',
          subtitle: 'Katalog erstellen',
        },
        {
          content: 'Nachdem Sie auf Okay geklickt haben, wird der neue Katalogknoten zur Liste hinzugefügt und Sie werden zur Detailseite weitergeleitet, auf der Sie Seiten hinzufügen können.',
          subtitle: 'Katalog erstellen',
        },
        {
          content: 'Im oberen Teil der Ansicht sehen Sie die allgemeinen Informationen eines Katalogs.',
          subtitle: 'Katalogstruktur - Detailansicht',
        },
        {
          content: 'Um neue Seiten hinzuzufügen, werden Sie nach zusätzlichen Metadaten wie Katalogschlüssel, Routencode, Reichweite von bis und einem zugehörigen Dienstanbieter gefragt. Konventionen sollten der DTP-Richtlinie folgen. Klicken Sie nach Eingabe der Details auf Struktur aktualisieren.',
          subtitle: 'Katalogstruktur - Detailansicht',
        },
        {
          content: 'Sie sehen die aktualisierten Seiten des Katalogs',
          subtitle: 'Katalogstruktur - Detailansicht',
        },
        {
          content: 'Im Gegensatz zur manuellen Erstellung eines Katalogs können Sie XML-Dateien verwenden, um die Struktur automatisch für Sie zu erstellen.',
          subtitle: 'XML hochladen',
        },
        {
          content: 'Navigieren Sie einfach zu der entsprechenden Datei auf Ihrem lokalen Computer und in Ihrem Dateisystem, um sie auf CPT hochzuladen.',
          subtitle: 'XML hochladen',
        },
        {
          content: 'Klicken Sie auf OK, um den Katalog zu erstellen',
          subtitle: 'XML hochladen',
        },
        {
          content: 'Wenn die Struktur im XML korrekt ist, wird der Katalogknoten automatisch erstellt.',
          subtitle: 'XML hochladen',
        },
        {
          content: 'Klicken Sie auf die Schaltfläche Löschen, um den Katalog zu löschen. Ein Bestätigungsdialog wird angezeigt.',
          subtitle: 'Katalog löschen',
        },
        {
          content: 'Wenn Sie im Löschbestätigungsdialog auf OK klicken, werden der Katalog und die entsprechenden Arbeitsseiten gelöscht, wenn der Katalog keine Assets enthält.',
          subtitle: 'Katalog löschen',
        },
        {
          content: 'Wenn der Katalog Assets enthält, erfolgt keine Löschung und dem Benutzer wird dieselbe Meldung angezeigt.',
          subtitle: 'Katalog löschen',
        },
      ],
      photographerGuide: [
        {
          content: 'Klicken Sie auf die Schaltfläche Dateien hochladen',
          subtitle: 'Hochladen',
        },
        {
          content: 'Wenn Sie auf die Schaltfläche Dateien hochladen klicken, können Sie umbenannte Dateien von Ihrem lokalen Computer auswählen und hochladen',
          subtitle: 'Hochladen',
        },
        {
          content: 'Während der Upload läuft, sehen Sie auf der rechten Seite ein Dialogfenster, das den aktuellen Status und die verbleibende Zeit anzeigt, bis alle Dateien in unserem Tool bereitgestellt sind. Die Upload-Zeit hängt von vielen Faktoren wie Netzwerkgeschwindigkeit, Standort, Firewall-Zugriff, Hintergrundprozessen, mehreren Benutzern im Netzwerk usw. ab. Um den Prozess zu beschleunigen, versuchen Sie, einen oder alle dieser Faktoren zu optimieren. Während der Upload läuft, können Sie weitere Kataloge auswählen und den Vorgang auch dort starten. Uploads werden in einer Warteschlange gehandhabt und Schritt für Schritt sicher verarbeitet. Solange Sie Ihren Browser geöffnet haben, ist das Hochladen im Gange und kann abgeschlossen werden, ohne dass Sie es kontrollieren müssen.',
          subtitle: 'Hochladen',
        },
        {
          content: 'Wenn es ein Validierungsproblem bei der Benennung von Assets gibt, wird es beim Bewegen des Kreuzes angezeigt und gibt bessere Einblicke',
          subtitle: 'Hochladen',
        },
        {
          content: 'In der Übersicht können Sie einen unserer aktuellen Kataloge nach seinen übergeordneten Namenskonventionen wie Katalogschlüssel z.B. OV8956C. Klicken Sie einfach auf einen Punkt und Sie gelangen zur nächsten Navigationsebene.',
          subtitle: 'Katalogauswahl',
        },
        {
          content: 'Nachdem Sie den Katalog ausgewählt haben, können Sie einen Routencode auswählen, der sich auf diesen einzigartigen Katalog bezieht. Auch hier klicken Sie einfach auf einen Punkt und Sie gelangen zur nächsten Navigationsebene.',
          subtitle: 'Routencode-Auswahl',
        },
        {
          content: 'Die letzte Ebene für Ihre Auswahl ist die Seite. Wählen Sie eine Seite aus, auf der Sie Ihre hochgeladenen Assets anzeigen möchten.',
          subtitle: 'Seitenauswahl',
        },
        {
          content: 'Sobald der Upload abgeschlossen ist, wird der Vorgang zum Zuweisen von Metadaten automatisch abgeschlossen',
          subtitle: 'Metadaten zuweisen',
        },
        {
          content: 'Sobald die Zuweisung von Metadaten abgeschlossen ist, wird die Synchronisierung mit SAM automatisch abgeschlossen',
          subtitle: 'Synchronisieren',
        },
        {
          content: 'Wenn Sie auf View in SAM klicken, werden Sie zu SAM weitergeleitet',
          subtitle: 'SAM',
        },
        {
          content: 'Sie können die synchronisierten Dateien in SAM anzeigen.',
          subtitle: 'SAM',
        },
        {
          content: 'Um den vollständigen Dateinamen, alle zugehörigen Metadaten, den Zeitstempel für die Synchronisierung mit SAM und den Asset-Status direkt anzuzeigen, klicken Sie auf die Schaltfläche Listenansicht.',
          subtitle: 'Listenansicht',
        },
        {
          content: 'Die Listenansicht konzentriert sich auf zusätzliche Informationen zu einem Asset. Außerdem gibt es eine integrierte Suche, die Ihnen hilft, Assets nach Ihren Eingaben zu filtern. Die Suche wird in den Dateinamen- und Metadatenfeldern der Listentabelle durchgeführt. Standardmäßig zeigt die Liste 50 Einträge. Sie können diesen Wert an Ihre Bedürfnisse anpassen. Abhängig von Ihrer Eingabe wird die Liste auch nummeriert.',
          subtitle: 'Listenansicht',
        },
      ],
      rsProviderGuide: [
        {
          content: 'Klicken Sie auf die Schaltfläche Dateien hochladen',
          subtitle: 'Hochladen',
        },
        {
          content: 'Wenn Sie auf die Schaltfläche Dateien hochladen klicken, können Sie umbenannte Dateien von Ihrem lokalen Computer auswählen und hochladen',
          subtitle: 'Hochladen',
        },
        {
          content: 'Während der Upload läuft, sehen Sie auf der rechten Seite ein Dialogfenster, das den aktuellen Status und die verbleibende Zeit anzeigt, bis alle Dateien in unserem Tool bereitgestellt sind. Die Upload-Zeit hängt von vielen Faktoren wie Netzwerkgeschwindigkeit, Standort, Firewall-Zugriff, Hintergrundprozessen, mehreren Benutzern im Netzwerk usw. ab. Um den Prozess zu beschleunigen, versuchen Sie, einen oder alle dieser Faktoren zu optimieren. Während der Upload läuft, können Sie weitere Kataloge auswählen und den Vorgang auch dort starten. Uploads werden in einer Warteschlange gehandhabt und Schritt für Schritt sicher verarbeitet. Solange Sie Ihren Browser geöffnet haben, ist das Hochladen im Gange und kann abgeschlossen werden, ohne dass Sie es kontrollieren müssen.',
          subtitle: 'Hochladen',
        },
        {
          content: 'Wenn es ein Validierungsproblem bei der Benennung von Assets gibt, wird es beim Bewegen des Kreuzes angezeigt und gibt bessere Einblicke',
          subtitle: 'Hochladen',
        },
        {
          content: 'In der Übersicht können Sie einen unserer aktuellen Kataloge nach seinen übergeordneten Namenskonventionen wie Katalogschlüssel z.B. OVAS33C. Klicken Sie einfach auf einen Punkt und Sie gelangen zur nächsten Navigationsebene.',
          subtitle: 'Katalogauswahl',
        },
        {
          content: 'Sobald der Upload abgeschlossen ist, wird der Vorgang zum Zuweisen von Metadaten automatisch abgeschlossen',
          subtitle: 'Metadaten zuweisen',
        },
        {
          content: 'Sobald die Zuweisung von Metadaten abgeschlossen ist, wird die Synchronisierung mit SAM automatisch abgeschlossen',
          subtitle: 'Synchronisieren',
        },
        {
          content: 'Wenn Sie auf View in SAM klicken, werden Sie zu SAM weitergeleitet',
          subtitle: 'SAM',
        },
        {
          content: 'Sie können die synchronisierten Dateien in SAM anzeigen.',
          subtitle: 'SAM',
        },
        {
          content: 'Um den vollständigen Dateinamen, alle zugehörigen Metadaten, den Zeitstempel für die Synchronisierung mit SAM und den Asset-Status direkt anzuzeigen, klicken Sie auf die Schaltfläche Listenansicht.',
          subtitle: 'Listenansicht',
        },
        {
          content: 'Die Listenansicht konzentriert sich auf zusätzliche Informationen zu einem Asset. Außerdem gibt es eine integrierte Suche, die Ihnen hilft, Assets nach Ihren Eingaben zu filtern. Die Suche wird in den Dateinamen- und Metadatenfeldern der Listentabelle durchgeführt. Standardmäßig zeigt die Liste 50 Einträge. Sie können diesen Wert an Ihre Bedürfnisse anpassen. Abhängig von Ihrer Eingabe wird die Liste auch nummeriert.',
          subtitle: 'Listenansicht',
        },
      ],
    },
  },
};
