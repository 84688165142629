<template>
  <v-app>
    <Navbar/>
    <router-view/>
  </v-app>
</template>

<script>
import {
  PRINT_USER_ROLE,
  PHOTOGRAPHER_ROLE,
  PRINT_USER_HOME_PATH,
  PHOTOGRAPHER_HOME_PATH,
  REPRO_SERVICE_PROVIDER_ROLE,
  REPRO_SERVICE_PROVIDER_HOME_PATH,
} from '@/modules/constants';
import { redirectIfNotEqualsCurrentPath } from '@/modules/vueUtils';
import Navbar from '@/components/Navbar.vue';

export default {
  name: 'App',
  components: {
    Navbar,
  },
  watch: {
    $route: {
      handler(newValue) {
        /**
         * This hack is added as issuer is appended to URL automatically for security reasons.
         * This will check the issuer's claim but also retain our existing functionality
         */
        if (newValue.path) {
          const newPath = newValue.path.split('&')[0];
          // Update the route with the new path without iss
          this.$router.replace({ ...newValue, path: newPath });
        }
        if (newValue.path === '/') {
          const roles = this.$store.state.keycloak?.tokenParsed?.role;
          if (Array.isArray(roles) && roles.includes(PRINT_USER_ROLE)) {
            redirectIfNotEqualsCurrentPath(this.$router, PRINT_USER_HOME_PATH);
          } else if (roles.includes(PHOTOGRAPHER_ROLE)) {
            redirectIfNotEqualsCurrentPath(this.$router, PHOTOGRAPHER_HOME_PATH);
          } else if (roles.includes(REPRO_SERVICE_PROVIDER_ROLE)) {
            redirectIfNotEqualsCurrentPath(this.$router, REPRO_SERVICE_PROVIDER_HOME_PATH);
          } else {
            redirectIfNotEqualsCurrentPath(this.$router, '/error');
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: OttoSans,serif;
}
h2 {
  @include font($font300, OttoSans);
  font-weight: bold;
}
</style>
