<template>
  <div class="breadcrumbs py-8 px-10">
    <v-chip @click="redirect(getRootPath(''))" v-bind="activeProps(isHomepage)" label>
      {{ $t('title.catalogue') }}
    </v-chip>
    <template v-if="catalogueKey">
      <v-icon class="breadcrumbs-icon">mdi-greater-than</v-icon>
      <v-chip
        @click="redirect(getRootPath(`/${catalogueKey}`))"
        v-bind="activeProps(isCatalogue)" label>
        {{ catalogueKey }}
      </v-chip>
    </template>
    <template v-if="routeCode">
      <v-icon class="breadcrumbs-icon">mdi-greater-than</v-icon>
      <v-chip
        @click="redirect(getRootPath(`/${catalogueKey}/${routeCode}`))"
        v-bind="activeProps(isRouteCode)" label>
        {{ routeCode }}
      </v-chip>
    </template>
    <template v-if="workPage">
      <v-icon class="breadcrumbs-icon">mdi-greater-than</v-icon>
      <v-chip
        @click="redirect(getRootPath(`/${catalogueKey}/${routeCode}/${workPage}`))"
        v-bind="activeProps(isWorkPage)" label>
        {{ workPage }}
      </v-chip>
    </template>
    <template v-if="this.displayViewInSamButton">
      <v-chip
        color="black"
        text-color="white"
        class="float-end"
        @click="redirectToSAM"
        v-bind="activeProps(isWorkPage)" label>
        {{ $t('actions.viewInSam') }}
      </v-chip>
    </template>
    <UploadAssetsButton class="float-end" v-if="displayButton"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ERROR_PATH,
  PHOTOGRAPHER_HOME_PATH,
  PHOTOGRAPHER_ROLE,
  REPRO_SERVICE_PROVIDER_HOME_PATH,
  REPRO_SERVICE_PROVIDER_ROLE,
} from '@/modules/constants';
import { redirectIfNotEqualsCurrentPath } from '@/modules/vueUtils';
import { getApplicationUserRole } from '@/modules/getApplicationUserRole';
import UploadAssetsButton from '@/components/photographer/UploadAssetsButton.vue';

const { VUE_APP_SAM_URL } = process.env;
export default {
  name: 'BreadCrumbs',
  components: { UploadAssetsButton },
  data() {
    return {
      activeCrumb: {
        color: 'black',
        'text-color': 'white',
      },
      samQueryOperators: {
        equals: 'Equals',
      },
      samMetadataFields: {
        catalogue: 'print_reference_product_image_catalog',
        routeCode: 'print_reference_product_image_route',
        workPage: 'print_reference_product_image_working_page',
        season: 'print_reference_product_image_season',
      },
      currentRoutePath: '',
    };
  },
  computed: {
    ...mapGetters([
      'getUserRoles',
      'getSelectedCatalogueKey',
      'getSelectedRouteCode',
      'getSelectedWorkPage',
      'getSelectedCatalogue',
    ]),
    catalogueKey() {
      return this.$route.params.catalogueKey;
    },
    routeCode() {
      return this.$route.params.routeCode;
    },
    workPage() {
      return this.$route.params.workPage;
    },
    isHomepage() {
      return !this.$route.params.catalogueKey;
    },
    isCatalogue() {
      return !this.$route.params.routeCode;
    },
    isRouteCode() {
      return !this.$route.params.workPage;
    },
    isWorkPage() {
      return this.$route.params.workPage;
    },
    displayViewInSamButton() {
      return this.getSelectedCatalogueKey;
    },
    displayButton() {
      return this.currentRoutePath === REPRO_SERVICE_PROVIDER_HOME_PATH
        || this.currentRoutePath === PHOTOGRAPHER_HOME_PATH;
    },
  },
  watch: {
    $route: {
      handler(newValue) {
        this.currentRoutePath = newValue.path;
      },
      immediate: true,
    },
  },
  methods: {
    activeProps(isActive) {
      return isActive ? this.activeCrumb : {};
    },
    redirect(path) {
      return redirectIfNotEqualsCurrentPath(this.$router, path);
    },
    getRootPath(route) {
      const role = getApplicationUserRole(this.getUserRoles, this.$router);
      switch (role) {
        case PHOTOGRAPHER_ROLE:
          return PHOTOGRAPHER_HOME_PATH + route;
        case REPRO_SERVICE_PROVIDER_ROLE:
          return REPRO_SERVICE_PROVIDER_HOME_PATH + route;
        default:
          return ERROR_PATH;
      }
    },
    samQuery(parameter, value, operator = this.samQueryOperators.equals) {
      return `sq_af=${JSON.stringify({
        op: operator,
        et: 'Version',
        pd: parameter,
        q: [value],
      })}`;
    },
    getFilterUrl() {
      const andQueryJoiner = 'sqo_op=And';
      let filterQuery = '';
      if (this.getSelectedCatalogueKey) {
        filterQuery += `?${andQueryJoiner}&${this.samQuery(
          this.samMetadataFields.catalogue, `${this.getSelectedCatalogue.CompanyId}${this.getSelectedCatalogue.CatalogueType}`,
        )}`;
      }
      if (this.getSelectedRouteCode) {
        filterQuery += `&${this.samQuery(this.samMetadataFields.routeCode, this.getSelectedRouteCode)}`;
      }
      if (this.getSelectedWorkPage) {
        filterQuery += `&${this.samQuery(this.samMetadataFields.workPage, this.getSelectedWorkPage)}`;
      }
      filterQuery += `&${this.samQuery(this.samMetadataFields.season, this.getSelectedCatalogue.SeasonId)}`;
      return encodeURI(filterQuery);
    },
    redirectToSAM() {
      window.open(`${VUE_APP_SAM_URL}${this.getFilterUrl()}`);
    },
  },
};
</script>

<style scoped lang="scss">
.breadcrumbs {
  background-color: $grey50;
}

.breadcrumbs-icon {
  font-size: 20px;
  margin: 0 12px;
}

.breadcrumbs-button.v-btn {
  background-color: $pl_color-black100;
}
</style>
