<template>
  <v-app-bar class="mt-1" color="white" app absolute>
    <a href="/">
      <img src="@/assets/cpt_logo.png" class="navbar-logo"/>
    </a>
    <v-spacer></v-spacer>
    <div class="d-flex justify-end">
      <v-select style="width: 100px" :items="items" id="nameSelect"
                @change="changeLocale" v-model="defaultSelected">
        <template v-slot:selection="{ item }">
          <img class="catalogue-production-locale" :src="item.image">{{ item.name }}
        </template>
        <template v-slot:item="{ item }">
          <img class="catalogue-production-locale" :src="item.image">{{ item.name }}
        </template>
      </v-select>
      <UserGuideButton/>
      <Avatar></Avatar>
    </div>
  </v-app-bar>
</template>
<script>

import Avatar from '@/components/Avatar.vue';
import UserGuideButton from '@/components/UserGuideButton.vue';
import { LOCALES } from '@/modules/constants';

const ukImage = require('../assets/united_kingdom.png');
const germanImage = require('../assets/german.png');

export default {
  name: 'Navbar',
  components: { UserGuideButton, Avatar },
  data() {
    return {
      defaultSelected: {
        name: LOCALES.EN, image: ukImage,
      },
      items: [
        { name: LOCALES.EN, image: ukImage },
        { name: LOCALES.DE, image: germanImage },
      ],
    };
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale.name;
    },
  },
};
</script>

<style scoped lang="scss">
.navbar-logo {
  height: 36px;
  margin-left: 10px;
}
.v-input {
  margin-top: 20px;
}
.catalogue-production-locale {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  margin-left: 9px;
}
</style>
