import axios from 'axios';
import { CATALOGUE_ENDPOINT, CREATE_CATALOGUE_ENDPOINT } from '@/modules/constants';

const {
  VUE_APP_GET_ALL_CATALOGUES_API,
} = process.env;

export const getCatalogues = async (all) => {
  const headers = {
    authorization: `Bearer ${localStorage.getItem('access_token')}`,
  };
  const queryParams = all ? '?all=true' : '';
  try {
    const receivedResponse = await axios.get(`${VUE_APP_GET_ALL_CATALOGUES_API}${CATALOGUE_ENDPOINT}${queryParams}`, {
      headers,
    });
    return receivedResponse.data.catalogues;
  } catch (error) {
    return [];
  }
};

export const createCatalogue = async (catalogueInformation) => {
  try {
    const headers = {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    };
    const receivedResponse = await axios.post(`${VUE_APP_GET_ALL_CATALOGUES_API}${CREATE_CATALOGUE_ENDPOINT}`, catalogueInformation, { headers });
    return { success: true, catalog: receivedResponse.data.catalog };
  } catch (error) {
    console.error(error);
    return { success: false };
  }
};

export const deleteCatalogue = async (catalogueKey) => {
  try {
    const headers = {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    };
    await axios.delete(`${VUE_APP_GET_ALL_CATALOGUES_API}${CATALOGUE_ENDPOINT}/${catalogueKey}`, { headers });
    return { success: true };
  } catch (error) {
    console.error(error);
    return { success: false };
  }
};
