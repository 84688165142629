import {
  PHOTOGRAPHER_HOME_PATH,
  PHOTOGRAPHER_ROLE,
  REPRO_SERVICE_PROVIDER_HOME_PATH,
  REPRO_SERVICE_PROVIDER_ROLE,
  ASSET_TYPE, PRINT_USER_ROLE, PRINT_USER_HOME_PATH,
} from './constants';

export const getApplicationUserRole = (keycloakRoles = [], route) => {
  if (keycloakRoles.includes(PHOTOGRAPHER_ROLE)
    && route.currentRoute.fullPath.includes(PHOTOGRAPHER_HOME_PATH)) {
    return PHOTOGRAPHER_ROLE;
  }
  if (keycloakRoles.includes(REPRO_SERVICE_PROVIDER_ROLE)
    && route.currentRoute.fullPath.includes(REPRO_SERVICE_PROVIDER_HOME_PATH)) {
    return REPRO_SERVICE_PROVIDER_ROLE;
  }
  if (keycloakRoles.includes(PRINT_USER_ROLE)
    && route.currentRoute.fullPath.includes(PRINT_USER_HOME_PATH)) {
    return PRINT_USER_ROLE;
  }
  return 'error';
};

export const getAssetType = (keycloakRoles, route) => {
  const role = getApplicationUserRole(keycloakRoles, route);
  switch (role) {
    case PHOTOGRAPHER_ROLE:
      return ASSET_TYPE.IMAGE;
    case REPRO_SERVICE_PROVIDER_ROLE:
      return ASSET_TYPE.DESIGN_DOC;
    default:
      return 'invalid';
  }
};

export default getApplicationUserRole;
