<template>
  <div>
    <CreateCatalogueForm v-model="displayCatalogueForm"/>
    <v-row class="pt-10" justify="space-between" no-gutters>
      <h1 class="catalogue-dashboard-title mb-4 mr-4">{{ $t('applicationName') }}</h1>
      <div class="catalogue-dashboard-buttons d-flex flex-wrap justify-space-between">
        <Button data-test="catalogue-dashboard-create-catalogue-button"
                @click="displayCatalogueForm = true" :label="$t('actions.createCatalogue')"/>
        <UploadXmlButton @uploadSuccess="uploadSuccess()"/>
      </div>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="catalogues"
      :search="search"
      id="catalogueDashboard"
      class="otto-table"
      :page.sync="page"
      :item-class="isSelected"
      :loading = "getCataloguesLoadingStatus"
      loading-text="loading.."
      :sort-by.sync="sortBy"
      :sort-desc="sortDesc"
      @click:row="setSelected($event)"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          :label="$t('actions.search')"
          class="mx-4"
        ></v-text-field>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import Button from '@/components/Button.vue';
import UploadXmlButton from '@/components/printUser/UploadXmlButton.vue';
import CreateCatalogueForm from '@/components/printUser/CreateCatalogueForm.vue';

export default {
  name: 'CatalogueDashboard',
  components: {
    Button,
    UploadXmlButton,
    CreateCatalogueForm,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('catalogueDataFields.catalogueKey'), align: 'start', value: 'CatalogueKey', class: 'v-data-table-header',
        },
        { text: this.$t('catalogueDataFields.catalogueName'), value: 'CatalogName' },
        { text: this.$t('catalogueDataFields.company'), value: 'Company' },
        { text: this.$t('catalogueDataFields.catalogueType'), value: 'CatalogueType' },
        { text: this.$t('catalogueDataFields.seasonId'), value: 'SeasonId' },
        { text: this.$t('catalogueDataFields.seasonName'), value: 'SeasonName' },
        { text: this.$t('catalogueDataFields.catalogueVersion'), value: 'CatalogueVersion' },
        { text: this.$t('catalogueDataFields.year'), value: 'CatalogueYear' },
        { text: this.$t('catalogueDataFields.createdBy'), value: 'CreatedBy' },
        { text: this.$t('catalogueDataFields.updatedAt'), value: 'UpdatedAt', align: ' d-none' },
      ],
      search: '',
      page: 1,
      displayCatalogueForm: false,
      sortBy: 'UpdatedAt',
      sortDesc: true,
    };
  },
  computed: {
    ...mapState([
      'catalogues',
    ]),
    ...mapGetters(['getSelectedCatalogueKey', 'getCataloguesLoadingStatus']),
  },
  methods: {
    setSelected(item) {
      this.$store.commit('setSelectedCatalogueKey', item.CatalogueKey);
      this.$store.dispatch('fetchWorkPages', { catalogueKey: item.CatalogueKey });
    },
    isSelected(item) {
      return item.CatalogueKey === this.getSelectedCatalogueKey ? 'selected' : '';
    },
    uploadSuccess() {
      this.page = 1;
    },
  },
};
</script>

<style lang="scss">
.otto-table {
  @include font($font75, OttoSansThin);
  cursor: pointer;
}
.v-data-table thead{
  background-color: $grey25;
}
.v-data-table thead th span{
  @include font($font100, OttoSans);
  font-weight: bold;
  color: $pl_color-black100;
}
.v-data-table tbody tr.selected {
  background-color: $grey100;
}
.v-data-table tr:nth-child(even) {
  background-color: $grey25;
}
.catalogue-dashboard-title {
  font-size: 32px;
  font-weight: 500;
}
.catalogue-dashboard-buttons {
  row-gap: 16px;
}
</style>
