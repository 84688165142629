<template>
  <v-main class="default-app">
    <div class="d-flex align-center justify-center print-user-home-circular-loader" v-if="loading">
      <v-progress-circular indeterminate color="blue" size="150"/>
    </div>
    <v-container class="pl-10 pr-0 py-0" :class="dynamicRightPadding" fluid v-else>
      <v-row no-gutters>
        <v-col v-bind="dashboardWidth">
          <CatalogueDashboard />
        </v-col>
        <v-col class="d-flex" v-if="isCatalogueSelected">
          <v-divider class="print-user-home-separator d-none d-md-flex " vertical />
          <CatalogueDetails />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import CatalogueDashboard from '@/components/printUser/CatalogueDashboard.vue';
import CatalogueDetails from '@/components/printUser/CatalogueDetails.vue';

export default {
  mounted() {
    this.$store.dispatch('fetchServiceProviders');
    this.$store.dispatch('setCatalogueDashboard', { all: true });
  },
  name: 'PrintUserHome',
  components: {
    CatalogueDashboard,
    CatalogueDetails,
  },
  computed: {
    ...mapGetters(['getCreateCatalogueLoadingStatus', 'getCreateWorkPagesLoadingStatus', 'getSelectedCatalogueKey']),
    dashboardWidth() {
      if (this.isCatalogueSelected) {
        return { md: 4, lg: 4, xl: 4 };
      }
      return { md: 12, lg: 12, xl: 12 };
    },
    dynamicRightPadding() {
      if (this.isCatalogueSelected) {
        return '';
      }
      return 'pr-10';
    },
    isCatalogueSelected() {
      return this.getSelectedCatalogueKey !== '';
    },
    loading() {
      return this.getCreateCatalogueLoadingStatus || this.getCreateWorkPagesLoadingStatus;
    },
  },
};
</script>

<style scoped lang="scss">
.default-app {
  font-family: OttoSansThin;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $grey25;
}
.print-user-home-separator {
  border-right: solid dimgray;
  border-right-width: 1px;
  position: fixed;
  top: 0;
  bottom: 0;
}
.print-user-home-circular-loader {
  min-height: 100%;
}
</style>
