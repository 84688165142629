import axios from 'axios';
import {
  WORKPAGE_THUMBNAILS_ENDPOINT, ASSET_UPDATE_S3_UPLOAD_STATUS, VALIDATE_ASSET_FILENAME_ENDPOINT,
} from '../constants';

const { VUE_APP_GET_ALL_CATALOGUES_API } = process.env;

const workPageAssetsClient = async (endpoint, data) => {
  try {
    const url = `${VUE_APP_GET_ALL_CATALOGUES_API}${endpoint}`;
    const config = {
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const response = await axios.post(url, data, config);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

const thumbnailsClient = async (endpoint) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const response = await axios.get(endpoint, config);
    return { success: true, data: response.data.thumbnailUrls };
  } catch (error) {
    console.log(error.message);
    return { success: false };
  }
};

export const updateAssetS3UploadStatus = async (assetDetails) => workPageAssetsClient(
  ASSET_UPDATE_S3_UPLOAD_STATUS, { ...assetDetails },
);

export const getWorkPageAssetThumbnails = async (
  catalogueKey,
  routeCode,
  workPageNumber,
  type,
) => thumbnailsClient(
  `${VUE_APP_GET_ALL_CATALOGUES_API}${WORKPAGE_THUMBNAILS_ENDPOINT}/${catalogueKey}/${routeCode}/${workPageNumber}?type=${type}`,
);

export const getCatalogueAssetThumbnails = async (catalogueKey, type) => thumbnailsClient(
  `${VUE_APP_GET_ALL_CATALOGUES_API}${WORKPAGE_THUMBNAILS_ENDPOINT}/${catalogueKey}?type=${type}`,
);

export const validateAssetFilename = async (assetDetails) => workPageAssetsClient(
  VALIDATE_ASSET_FILENAME_ENDPOINT, assetDetails,
);

export default getWorkPageAssetThumbnails;
