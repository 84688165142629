<template>
  <v-main class="pt-16">
    <v-container>
      <h1 class="catalogue-detail-title">{{ getSelectedCatalogueKey }}</h1>
      <p class="mt-6 mb-16">{{ $t('message.catalogue') }}</p>
      <div v-if="getWorkPagesLoadingStatus">
        <v-progress-linear
          indeterminate
          color="blue"
        ></v-progress-linear>
      </div>
      <div v-for="routeCode in routeCodes"
           :key="routeCode">
        <CatalogueDataEntry
          :to="`${$route.path}/${routeCode}`"
          :entry-key="routeCode"/>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import { redirectIfNotEqualsCurrentPath } from '@/modules/vueUtils';
import CatalogueDataEntry from './CatalogueDataEntry.vue';

export default {
  name: 'CatalogueDetails',
  components: { CatalogueDataEntry },
  computed: {
    ...mapGetters(['getSelectedCatalogueKey', 'getCatalogueData', 'getWorkPagesLoadingStatus']),
    routeCodes() {
      const catalogueData = this.getCatalogueData;
      return catalogueData ? Object.keys(catalogueData) : [];
    },
  },
  watch: {
    getCatalogueData(newValue) {
      if (newValue === null) {
        redirectIfNotEqualsCurrentPath(this.$router, '/error');
      }
    },
  },
  created() {
    if (this.getCatalogueData === null) {
      redirectIfNotEqualsCurrentPath(this.$router, '/error');
    }
  },
};
</script>

<style scoped lang="scss">
.catalogue-detail-title {
  font-weight: 100;
  font-family: OttoSansThin, serif;
  font-size: 34px;
}
</style>
