import axios from 'axios';

const uploadFile = async (signedUrl, file, updateUploadProgress) => {
  let success = false;
  const config = {
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress: (data) => updateUploadProgress(data),
  };
  try {
    const response = await axios.put(signedUrl, file, config);
    success = response.status === 200;
  } catch (error) {
    console.log('Error uploading file', error);
  }
  return { success };
};
export default uploadFile;
