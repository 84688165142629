// ROLES
export const PRINT_USER_ROLE = 'CatalogueProductionTool-Creator';
export const PHOTOGRAPHER_ROLE = 'CatalogueProductionTool-ImageSupplier';
export const REPRO_SERVICE_PROVIDER_ROLE = 'CatalogueProductionTool-PageSupplier';

// ROLE BASED REDIRECTION URLS
export const PRINT_USER_HOME_PATH = '/print';
export const PHOTOGRAPHER_HOME_PATH = '/photo';
export const REPRO_SERVICE_PROVIDER_HOME_PATH = '/repro';
export const ERROR_PATH = '/error';

// SERVICE ENDPOINTS
export const CATALOGUE_ENDPOINT = '/catalogues';
export const CREATE_CATALOGUE_ENDPOINT = '/catalogue';
export const SERVICE_PROVIDER_ENDPOINT = '/service-provider';
export const CATALOGUE_XML_UPLOAD_ENDPOINT = '/catalogue/upload';
export const WORKPAGE_ENDPOINT = '/workpage';
export const SIGNED_URL_ENDPOINT = '/assets/metadata';
export const WORK_PAGE_ASSETS_ENDPOINT = '/assets';
export const ASSET_UPDATE_S3_UPLOAD_STATUS = '/assets/s3Status';
export const WORKPAGE_THUMBNAILS_ENDPOINT = '/thumbnails';
export const CREATE_WORKPAGE_ENDPOINT = '/workpages';
export const VALIDATE_ASSET_FILENAME_ENDPOINT = '/assets/validateFileName';

export const THUMBNAIL_EXPIRY_MILLIS = 15000;
export const ASSET_UPLOAD_STATUS = {
  PENDING: 'pending',
  IN_PROGRESS: 'inProgress',
  DONE: 'done',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
};

export const FILE_NAME_VALIDATION_STATUS = {
  name: 'fileNameValidationStatus',
  status: {
    VALID: 'valid',
    INVALID: 'invalid',
    PENDING: 'pending',
  },
};

export const RENAME_ASSETS_STATUS = {
  name: 'renameStatus',
  status: {
    PENDING: 'pending',
    DONE: 'done',
    FAILED: 'failed',
  },
};

export const SHAREDIEN_SYNC_STATUS = {
  name: 'sharedienUploadStatus',
  status: {
    PENDING: 'pending',
    DONE: 'done',
    FAILED: 'failed',
  },
};
export const ASSET_TYPE = {
  IMAGE: 'image',
  DESIGN_DOC: 'designDoc',
};
export const VALID_ASSET_EXTENSIONS = [
  'jpg', 'tif', 'jpeg', 'eps', 'ai', 'png', 'psd', 'zip', 'indd', 'idml', 'pdf',
];
export const ASSET_ITEM_RETRIEVAL_LIMIT = 600;

export const STATE = {
  CREATE_CATALOGUES: 'createCatalogues',
  CREATE_WORK_PAGES: 'createWorkPages',
  CATALOGUES: 'catalogues',
  WORK_PAGES: 'workPages',
  WORK_PAGE_ASSETS: 'workPageAssets',
  FETCH_ASSETS: 'fetchAssets',
};

export const CATALOGUE_OPERATION = {
  CATALOGUE_CREATE: 'CATALOGUE_CREATE',
  CATALOGUE_UPDATE: 'CATALOGUE_UPDATE',
};

export const WEBSOCKET_HEALTH_MESSAGE_INTERVAL = 60000;

export const WORK_PAGE_ASSETS_LAYOUT_MODE = {
  GRID_VIEW: 'grid',
  LIST_VIEW: 'list',
};

export const ASSET_OPERATION_TYPE = {
  ADD: 'add',
  UPDATE: 'update',
  DELETE: 'delete',
  THUMBNAIL_UPDATE: 'thumbnail_update',
};

export const SECONDS_EQUIVALENT = {
  DAY: 86400,
  HOUR: 3600,
  MINUTE: 60,
};

export const PRINT_USER_GUIDE_CONTENT = 'printUserGuide';
export const PHOTOGRAPHER_GUIDE_CONTENT = 'photographerGuide';
export const RS_PROVIDER_GUIDE_CONTENT = 'rsProviderGuide';

export const PRINT_USER_GUIDE_IMAGE = [
  {
    image: '/print_user/step-1.png',
  },
  {
    image: '/print_user/step-2.png',
  },
  {
    image: '/print_user/step-3.png',
  },
  {
    image: '/print_user/step-4.png',
  },
  {
    image: '/print_user/step-5.png',
  },
  {
    image: '/print_user/step-6.png',
  },
  {
    image: '/print_user/step-7.png',
  },
  {
    image: '/print_user/step-8.png',
  },
  {
    image: '/print_user/step-9.png',
  },
  {
    image: '/print_user/step-10.png',
  },
  {
    image: '/print_user/step-11.png',
  },
  {
    image: '/print_user/step-12.png',
  },
  {
    image: '/print_user/step-13.png',
  },
];

export const PHOTOGRAPHER_USER_GUIDE_IMAGE = [
  {
    image: '/photographer/step-1.png',
  },
  {
    image: '/photographer/step-2.png',
  },
  {
    image: '/photographer/step-3.png',
  },
  {
    image: '/photographer/step-4.png',
  },
  {
    image: '/photographer/step-5.png',
  },
  {
    image: '/photographer/step-6.png',
  },
  {
    image: '/photographer/step-7.png',
  },
  {
    image: '/photographer/step-7a.png',
  },
  {
    image: '/photographer/step-7b.png',
  },
  {
    image: '/photographer/step-8.png',
  },
  {
    image: '/photographer/step-9.png',
  },
  {
    image: '/photographer/step-10.png',
  },
  {
    image: '/photographer/step-11.png',
  },
];

export const RS_PROVIDER_USER_GUIDE_IMAGE = [
  {
    image: '/rs_provider/step-1.png',
  },
  {
    image: '/rs_provider/step-2.png',
  },
  {
    image: '/rs_provider/step-3.png',
  },
  {
    image: '/rs_provider/step-4.png',
  },
  {
    image: '/rs_provider/step-5.png',
  },
  {
    image: '/rs_provider/step-6.png',
  },
  {
    image: '/rs_provider/step-7.png',
  },
  {
    image: '/rs_provider/step-8.png',
  },
  {
    image: '/rs_provider/step-9.png',
  },
  {
    image: '/rs_provider/step-10.png',
  },
  {
    image: '/rs_provider/step-11.png',
  },
];

export const LOCALES = {
  EN: 'en',
  DE: 'de',
};
