<template>
  <div class="d-flex">
    <v-tooltip bottom nudge-right="72">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <StatusIcon
            class="action-icon-spacing"
            data-test="assign-metadata-status"
            :status="getAssignMetadataStatus"
          >
            <svg><use xlink:href="@/assets/pl_icon_tag_small.svg#pl_icon_tag_small"/></svg>
          </StatusIcon>
        </div>
      </template>
      <span>{{ getAssignMetadataMessage }}</span>
    </v-tooltip>
    <v-tooltip bottom nudge-right="12">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <StatusIcon
            class="action-icon-extra-spacing"
            data-test="sharedien-sync-status"
            :status="getSharedienSyncStatus">
            <svg><use xlink:href="@/assets/pl_icon_checked_small.svg#pl_icon_checked_small"/></svg>
          </StatusIcon>
        </div>
      </template>
      <span>{{ getSharedienSyncMessage }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SHAREDIEN_SYNC_STATUS, ASSET_TYPE, RENAME_ASSETS_STATUS } from '@/modules/constants';
import StatusIcon from '../StatusIcon.vue';
import { getAssetType } from '../../modules/getApplicationUserRole';

export default {
  name: 'ActionIcons',
  components: { StatusIcon },
  props: {
    asset: Object,
  },
  computed: {
    ...mapGetters(['getUserRoles']),
    getAssignMetadataMessage() {
      let message = `${this.$t('actions.assignMetadata')}: `;
      switch (this.getAssignMetadataStatus) {
        case 'success': message += 'Success'; break;
        case 'pending': message += 'In Progress'; break;
        case 'failed': message += 'Failed'; break;
        default: message += 'Pending'; break;
      }
      return message;
    },
    getSharedienSyncMessage() {
      let message = `${this.$t('actions.sync')}: `;
      switch (this.getSharedienSyncStatus) {
        case 'success': message += 'Success'; break;
        case 'failed': message += 'Failed'; break;
        case 'pending': message += 'In Progress'; break;
        default: message += 'Pending'; break;
      }
      return message;
    },
    getSharedienSyncStatus() {
      const { status } = this.asset[SHAREDIEN_SYNC_STATUS.name];
      switch (status) {
        case SHAREDIEN_SYNC_STATUS.status.DONE: return 'success';
        case SHAREDIEN_SYNC_STATUS.status.FAILED: return 'failed';
        case SHAREDIEN_SYNC_STATUS.status.PENDING: return 'pending';
        default: return 'inactive';
      }
    },
    getAssignMetadataStatus() {
      let status = 'inactive';
      const assetType = getAssetType(this.getUserRoles, this.$router);
      if (this.asset.renameStatus === RENAME_ASSETS_STATUS.status.FAILED) {
        status = 'failed';
      } else if (assetType === ASSET_TYPE.IMAGE) {
        status = 'pending';
        if (this.asset.figureNumber && this.asset.sharedienName) {
          status = 'success';
        }
      } else if (assetType === ASSET_TYPE.DESIGN_DOC) {
        status = 'pending';
        if (this.asset.pagina && this.asset.documentStatus && this.asset.sharedienName) {
          status = 'success';
        }
      }
      return status;
    },
  },
};
</script>

<style scoped lang="scss">
.action-icon-spacing {
  margin-right: 6px;
}
.action-icon-extra-spacing {
  margin-right: 30px;
}
</style>
