import store from '@/store';

export const isUserAuthorised = (matchedRoutes) => {
  let isRoleAuthorised = false;
  const roles = store?.state?.keycloak?.tokenParsed?.role;
  if (Array.isArray(roles) && matchedRoutes.some((route) => route?.meta?.roles_allowed)) {
    isRoleAuthorised = matchedRoutes.some(
      (route) => route?.meta?.roles_allowed?.some(
        (role) => roles.includes(role),
      ),
    );
  }
  return isRoleAuthorised;
};

export default isUserAuthorised;
