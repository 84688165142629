<template>
  <div class="detail pa-3">
    <CloseButton @click="closeDetailView" />
    <h2 class="px-8 py-8 font-weight-medium">
      {{ $t('title.metadata') }}
    </h2>
    <div class="d-flex flex-column px-8 catalogue-master-data-text-field">
      <div class="catalogue-master-data-row">
        <v-text-field
          dense readonly disabled outlined
          :label="$t('catalogueDataFields.company')"
          :value="getSelectedCatalogue.Company"
          data-test="catalogue-master-data-company-name"
        ></v-text-field>
        <v-text-field
          dense readonly disabled outlined
          class="catalogue-master-data-text-field-large"
          :label="$t('catalogueDataFields.catalogueName')"
          :value="getSelectedCatalogue.CatalogName"
          data-test="catalogue-master-data-catalogue-name"
        ></v-text-field>
        <v-text-field
          dense readonly disabled outlined
          :label="$t('catalogueDataFields.catalogueType')"
          :value="getSelectedCatalogue.CatalogueType"
          data-test="catalogue-master-data-catalogue-type"
        ></v-text-field>
        <v-text-field
          dense readonly disabled outlined
          :label="$t('catalogueDataFields.seasonId')"
          :value="getSelectedCatalogue.SeasonId"
          data-test="catalogue-master-data-season-code"
        ></v-text-field>
      </div>
      <div class="catalogue-master-data-row">
        <v-text-field
          dense readonly disabled outlined
          :label="$t('catalogueDataFields.seasonName')"
          :value="getSelectedCatalogue.SeasonName"
          data-test="catalogue-master-data-season-name"
        ></v-text-field>
        <v-text-field
          dense readonly disabled outlined
          :label="$t('catalogueDataFields.year')"
          :value="getSelectedCatalogue.CatalogueYear"
          data-test="catalogue-master-data-catalogue-year"
        ></v-text-field>
        <v-text-field
          dense readonly disabled outlined
          :label="$t('catalogueDataFields.createdAt')"
          :value="getFormattedDate(getSelectedCatalogue.CreatedAt)"
          data-test="catalogue-master-data-created-on"
        ></v-text-field>
        <v-text-field
          dense readonly disabled outlined
          :label="$t('catalogueDataFields.updatedAt')"
          :value="getFormattedDate(getSelectedCatalogue.UpdatedAt)"
          data-test="catalogue-master-data-updated-on"
        ></v-text-field>
      </div>
      <div class="catalogue-master-data-row">
        <v-text-field
          dense readonly disabled outlined
          class="catalogue-master-data-text-field-small"
          :label="$t('catalogueDataFields.createdBy')"
          :value="getSelectedCatalogue.CreatedBy || ''"
          data-test="catalogue-master-data-created-by"
        ></v-text-field>
      </div>
      <div class="catalogue-master-data-row">
        <v-spacer></v-spacer>
        <v-btn data-test="catalogue-structure-delete-button"
               class="float-right catalogue-structure-delete-button"
               @click="openDeleteConfirmationDialog"
        >
          <svg class="catalogue-structure-button-icon" role="img">
            <use xlink:href="@/assets/pl_icon_delete.svg#pl_icon_delete"/>
          </svg>
          <p class="catalogue-structure-button-label">{{ $t('actions.delete') }}</p>
        </v-btn>
      </div>
    </div>
    <ConfirmationDialog
      data-test="catalogue-delete-confirmation-dialogue"
      v-model="confirmCatalogueDeletion"
      :title="$t('title.deleteCatalogueConfirmation')"
      :body="$t('confirmationMessages.deleteCatalogue')"
      @okayClicked="deleteCatalogueHandler"/>
    <InfoDialog
      data-test="catalogue-delete-error-dialog"
      :title="$t('title.catalogueHasAssets')"
      :message="$t('errorMessages.catalogueHasAssets')"
      v-model="showCatalogueHasAssetsErrorDialog"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CloseButton from '@/components/CloseButton.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import InfoDialog from '@/components/InfoDialog.vue';

export default {
  name: 'CatalogueMasterData',
  data() {
    return {
      confirmCatalogueDeletion: false,
    };
  },
  components: { CloseButton, ConfirmationDialog, InfoDialog },
  computed: {
    ...mapGetters([
      'getSelectedCatalogue',
      'getMdlDropdownList',
      'isCatalogueHasAssets',
    ]),
    showCatalogueHasAssetsErrorDialog: {
      get() {
        return this.isCatalogueHasAssets;
      },
      set() {
        this.$store.commit('setCatalogueHasAssets', false);
      },
    },
  },
  methods: {
    closeDetailView() {
      this.$store.commit('setSelectedCatalogueKey', '');
    },
    getFormattedDate(date) {
      const formattedDate = new Date(date);
      return formattedDate.toLocaleString();
    },
    deleteCatalogueHandler() {
      this.$store.dispatch('deleteCatalogue', this.getSelectedCatalogue.CatalogueKey);
    },
    openDeleteConfirmationDialog() {
      this.confirmCatalogueDeletion = true;
    },
  },
};
</script>

<style scoped lang="scss">
.detail {
  background: $pl_color-white100;
  border-bottom: solid dimgray;
  border-bottom-width: 1px;
  font-size: 8px;
  font-family: OttoSans;
}

.catalogue-master-data-text-field {
  .v-text-field {
    border: none transparent;
    padding-right: 24px;
  }
}
.catalogue-master-data-row {
  display: flex;
  flex-grow: 1;
}

.catalogue-master-data-text-field-large {
  min-width: 500px;
}

.catalogue-master-data-text-field-small{
  max-width: 300px;
}

.catalogue-structure-delete-button.v-btn {
  background-color: $red100;
  text-transform: none;
  color: $pl_color-white100;
  height: 40px;
  font-size: 16px;
  border-radius: 8px;
  margin-bottom: 32px;
}

.catalogue-structure-button-label {
  margin: 0 auto;
}

.catalogue-structure-button-icon {
  fill: $pl_color-white100;
  height: 15px;
  width: 15px;
  margin-right: 10px;
}
</style>
