import axios from 'axios';
import { SIGNED_URL_ENDPOINT } from '@/modules/constants';

const { VUE_APP_GET_ALL_CATALOGUES_API } = process.env;

const getSignedUrl = async (assetDetails) => {
  const {
    catalogueKey, routeCode, workPageNumber, fileName, type, fileSize, metadata,
  } = assetDetails;
  try {
    const url = `${VUE_APP_GET_ALL_CATALOGUES_API}${SIGNED_URL_ENDPOINT}`;
    const data = {
      catalogueKey,
      routeCode,
      workPageNumber,
      fileName,
      type,
      fileSize,
      metadata,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };

    const response = await axios.post(url, data, config);
    return {
      ...response.data.metadata,
      success: true,
    };
  } catch (error) {
    console.log('Failed to fetch signed url', error);
    return { success: false };
  }
};

export default getSignedUrl;
