<template>
  <div class="pa-3">
    <div class="px-8 py-4 pb-8">
      <h2 class="font-weight-medium">
        {{ $t('title.structure') }}
      </h2>
    </div>
    <v-form ref="createWorkPageForm">
      <div class="d-flex flex-wrap mx-8 pt-8 detail-box">
      <v-text-field
        dense
        class="catalogue-structure-text-fields catalogue-structure-catalogue-text-field"
        :label="$t('catalogueDataFields.catalogueKey')"
        :value="getSelectedCatalogueKey"
        outlined
        disabled
        readonly
        data-test="catalogue-structure-catalogue-key"
      ></v-text-field>
      <v-text-field
        dense
        class="catalogue-structure-text-fields catalogue-structure-input-text-fields"
        :label="$t('routeCodeData.routeCode')"
        v-model="workPageData.routeCode"
        :rules="[rules.required, rules.numbers, rules.length(3), rules.comparator('gte', 1)]"
        outlined
        data-test="catalogue-structure-route-code-input"
        maxlength="3"
      ></v-text-field>
      <v-text-field
        dense
        class="catalogue-structure-text-fields catalogue-structure-input-text-fields"
        :label="$t('routeCodeData.workPageFrom')"
        v-model="workPageData.workPageStartNumber"
        :rules="[rules.required, rules.numbers, rules.length(3),
        rules.comparator('gte', 2),
        workPageNumbersFromValidator]"
        outlined
        data-test="catalogue-structure-work-page-from-input"
        maxlength="3"
      ></v-text-field>
      <v-text-field
        dense
        class="catalogue-structure-text-fields catalogue-structure-input-text-fields"
        :label="$t('routeCodeData.workPageTo')"
        v-model="workPageData.workPageEndNumber"
        :rules="[
          rules.required,
          rules.numbers,
          rules.length(3),
          workPageNumbersToValidator]"
        outlined
        data-test="catalogue-structure-work-page-to-input"
        maxlength="3"
      ></v-text-field>
      <v-select
        dense
        :items="getMdlDropdownList"
        v-model="workPageData.serviceProvider"
        :label="$t('routeCodeData.serviceProvider')"
        :rules="[rules.required]"
        outlined
        data-test="catalogue-structure-service-provider-dropdown"
        class="catalogue-structure-text-fields catalogue-structure-input-text-fields"
      ></v-select>
      <v-spacer></v-spacer>
      <v-btn data-test="catalogue-structure-add-button"
             class="float-right catalogue-structure-add-button"
             @click="addWorkPage()"
      >
        <svg class="catalogue-structure-button-icon" role="img">
          <use xlink:href="@/assets/pl_icon_plus50.svg#pl_icon_plus50"/>
        </svg>
        <p class="catalogue-structure-button-label">{{ $t('actions.updateStructure') }}</p>
      </v-btn>
    </div>
    </v-form>
    <CatalogueHierarchy />
    <InfoDialog
      :title="$t('title.error')"
      :message="$t('errorMessages.createWorkPages')"
                v-model="showCreateWorkPagesErrorDialog"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { rules } from '@/modules/vueUtils';
import CatalogueHierarchy from '@/components/printUser/CatalogueHierarchy.vue';
import InfoDialog from '@/components/InfoDialog.vue';
import { STATE } from '@/modules/constants';

export default {
  name: 'CatalogueStructure',
  components: { CatalogueHierarchy, InfoDialog },
  data() {
    return {
      rules,
      workPageData: {
        routeCode: '',
        workPageStartNumber: '',
        workPageEndNumber: '',
        serviceProvider: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getSelectedCatalogueKey',
      'getMdlDropdownList',
      'getCreateWorkPagesErrorStatus',
    ]),
    showCreateWorkPagesErrorDialog: {
      get() {
        return this.getCreateWorkPagesErrorStatus;
      },
      set() {
        this.$store.commit('setErrorStatus', { name: STATE.CREATE_WORK_PAGES, status: false });
      },
    },
    workPageNumbersFromValidator() {
      return rules.comparator('lte', this.workPageData.workPageEndNumber)(this.workPageData.workPageStartNumber);
    },
    workPageNumbersToValidator() {
      return rules.comparator('gte', this.workPageData.workPageStartNumber)(this.workPageData.workPageEndNumber);
    },
  },
  methods: {
    addWorkPage() {
      if (this.$refs.createWorkPageForm.validate()) {
        this.$store.dispatch('createWorkPage', {
          catalogueKey: this.getSelectedCatalogueKey,
          routeCode: this.workPageData.routeCode,
          workPageStartNumber: this.workPageData.workPageStartNumber,
          workPageEndNumber: this.workPageData.workPageEndNumber,
          serviceProvider: this.workPageData.serviceProvider,
        });
        this.workPageData.routeCode = '';
        this.workPageData.workPageStartNumber = '';
        this.workPageData.workPageEndNumber = '';
        this.workPageData.serviceProvider = '';
        this.$refs.createWorkPageForm.resetValidation();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.catalogue-structure-button-icon {
  fill: $pl_color-white100;
  height: 15px;
  width: 15px;
}
.detail-box {
  background: $pl_color-white100;
  min-height: 120px;
  border-radius: 10px;
  font-size: 8px;
  font-family: OttoSans;
  padding-left: 32px;
  padding-right: 32px;
}
.catalogue-structure-add-button.v-btn {
  background-color: $black50;
  text-transform: none;
  color: $pl_color-white100;
  height: 40px;
  font-size: 16px;
  border-radius: 8px;
  margin-bottom: 32px;
}
.catalogue-structure-button-label {
  margin: 0 auto;
}
.catalogue-structure-text-fields.v-text-field {
  min-height: 48px;
  border: none transparent;
  max-width: 150px;
}

.catalogue-structure-input-text-fields.v-select {
  margin-right: 32px;
  min-width: 100px;
  max-width: 128px;
}

.catalogue-structure-input-text-fields.v-text-field {
  margin-left: 8px;
  min-width: 100px;
  max-width: 128px;
}
</style>
