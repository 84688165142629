<template>
  <v-dialog v-model='modalOpen' width="500">
    <v-card>
      <v-card-title class="grey lighten-2 confirmation-dialog-title">
        {{ title }}
      </v-card-title>
      <v-card-text class="mt-4">
        {{ body }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn data-test="cancel-button" color="primary" text @click="modalOpen = false" >
          {{ $t('actions.cancel') }}
        </v-btn>
        <v-btn data-test="okay-button" color="primary" text @click="okayClickHandler">
          {{ $t('actions.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: String,
    body: String,
  },
  computed: {
    modalOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    okayClickHandler() {
      this.$emit('okayClicked', { message: 'user confirmed' });
      this.modalOpen = false;
    },
  },
};
</script>
