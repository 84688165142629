<template>
  <v-dialog v-model="modalOpen" width="500">
    <v-card>
      <v-card-title class="info-dialog-title grey lighten-2">
        {{ title }}
      </v-card-title>
      <v-card-text class="mt-4">
        {{ message }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="modalOpen = false">
          {{ $t('actions.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'InfoDialog',
  props: {
    value: Boolean,
    title: String,
    message: String,
  },
  computed: {
    modalOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped>
.info-dialog-title {
  font-size: 24px;
}
</style>
