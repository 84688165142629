<template>
  <div class="d-flex align-center hierarchy-entry-wrapper"
       :class="hierarchyEntryType">
    <hr v-if="isWorkpage" class="connecting-line ml-n2"/>
    <div class="d-flex justify-space-between align-center px-4 hierarchy-entry">
      <div :class="titleClass">
        <CollapseButton
          :data-test="`catalogue-structure-${hierarchyEntryType}-collapse-button`"
          :status="collapse"
          v-on="$listeners"
          v-if="!isWorkpage"/>
        {{ name }}
      </div>
      <div class="d-flex">
        <v-chip class="hierarchy-entry-work-page-service-provider-chip"
                v-if="isWorkpage && serviceProvider">{{ this.serviceProvider }}</v-chip>
      </div>
    </div>
  </div>
</template>

<script>
import CollapseButton from '@/components/printUser/CollapseButton.vue';

export default {
  name: 'HierarchyEntry',
  props: {
    name: String,
    serviceProvider: String,
    level: {
      type: String,
      default: 'WORKPAGE',
    },
    collapse: Boolean,
  },
  components: {
    CollapseButton,
  },
  computed: {
    isWorkpage() {
      return this.level === 'WORKPAGE';
    },
    isCatalogue() {
      return this.level === 'CATALOGUE';
    },
    titleClass() {
      return this.isWorkpage ? 'regular-title' : 'bold-title';
    },
    hierarchyEntryType() {
      const entryTypeClassMap = {
        CATALOGUE: 'catalogue',
        ROUTECODE: 'routecode',
        WORKPAGE: 'workpage',
      };
      return entryTypeClassMap[this.level];
    },
  },
};
</script>

<style scoped lang="scss">
.bold-title {
  font-size: 14px;
  font-weight: 600;
}

.regular-title {
  font-size: 14px;
}

.workpage {
  height: 32px;
  margin-top: 0 !important;
  margin-bottom: 8px;
}

.routecode {
  height: 48px;
  margin-bottom: 16px;
  margin-left: 30px;
}

.catalogue {
  height: 48px;
  margin-bottom: 16px;
}

.hierarchy-entry {
  font-size: 12px;
  padding: 8px 0;
  background: $pl_color-white100;
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 100%;
}

.connecting-line {
  width: 16px;
  border: transparent;
  border-top: 1px dotted;
}

.hierarchy-entry-wrapper {
  z-index: 1;
  position: relative;
  font-family: OttoSans;
}

.hierarchy-entry-work-page-service-provider-chip.v-chip {
  font-size: 12px;
  color: $pl-color-white100;
  height: 24px;
  background-color: $black50;
}
</style>
