<template>
  <v-main class="default-app">
    <BreadCrumbs />
    <router-view />
    <UploadProgress />
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import UploadProgress from '@/components/photographer/UploadProgress.vue';
import BreadCrumbs from '@/components/photographer/BreadCrumbs.vue';
import { getApplicationUserRole, getAssetType } from '@/modules/getApplicationUserRole';
import {
  PHOTOGRAPHER_ROLE,
  WEBSOCKET_HEALTH_MESSAGE_INTERVAL,
} from '../modules/constants';

const {
  VUE_APP_WEBSOCKET_ENDPOINT,
} = process.env;

export default {
  name: 'AssetViewerHome',
  components: {
    BreadCrumbs,
    UploadProgress,
  },
  created() {
    const ws = new WebSocket(`${VUE_APP_WEBSOCKET_ENDPOINT}?auth=${localStorage.getItem('access_token')}`);
    this.$store.dispatch('initWebsocket', ws);
    this.getWebsocketConnection.onopen = () => {
      this.sendQueueMessages();
      setInterval(() => {
        this.$store.dispatch('webSocketSendMessage', {
          action: 'health',
        });
      }, WEBSOCKET_HEALTH_MESSAGE_INTERVAL);
    };
  },
  mounted() {
    this.$store.dispatch('setCatalogueDashboard');
  },
  computed: {
    ...mapGetters(['getSelectedCatalogueKey',
      'getUserRoles',
      'getWebsocketMessages',
      'getWebsocketConnection',
      'getCatalogueData',
      'getCatalogueKeys',
    ]),
  },
  methods: {
    sendQueueMessages() {
      const messagesToSend = this.getWebsocketMessages;
      messagesToSend.forEach((message) => {
        this.$store.dispatch('webSocketSendMessage', message);
      });
    },
  },
  watch: {
    '$route.params': {
      handler(newValue) {
        const {
          catalogueKey,
          routeCode,
          workPage,
        } = newValue;
        const role = getApplicationUserRole(this.getUserRoles, this.$router);
        const type = getAssetType(this.getUserRoles, this.$router);
        if (catalogueKey && this.getSelectedCatalogueKey !== catalogueKey) {
          if (role === PHOTOGRAPHER_ROLE) {
            this.$store.dispatch('fetchWorkPages', { catalogueKey });
          } else {
            this.$store.dispatch('fetchCatalogueAssets', {
              catalogueKey,
              type,
            });
          }
        }
        this.$store.commit('setSelectedCatalogueKey', catalogueKey);
        this.$store.commit('setSelectedRouteCode', routeCode);
        this.$store.commit('setSelectedWorkPage', workPage);
        this.$store.dispatch('fetchWorkPageAssets', {
          catalogueKey,
          routeCode,
          workPage,
          type,
        });
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.default-app {
  font-family: OttoSansThin;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $grey25;
}
</style>
