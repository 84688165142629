<template>
  <div>
    <CatalogueMasterData />
    <CatalogueStructure />
  </div>
</template>

<script>
import CatalogueMasterData from '@/components/printUser/CatalogueMasterData.vue';
import CatalogueStructure from '@/components/printUser/CatalogueStructure.vue';

export default {
  name: 'CatalogueDetails',
  components: { CatalogueStructure, CatalogueMasterData },
};
</script>
