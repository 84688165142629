import { getAssetType } from '@/modules/getApplicationUserRole';
import { WORK_PAGE_ASSETS_LAYOUT_MODE } from '@/modules/constants';
import { mapGetters } from 'vuex';
import { redirectIfNotEqualsCurrentPath } from '@/modules/vueUtils';

export default {
  data() {
    return {
      WORK_PAGE_ASSETS_LAYOUT_MODE,
    };
  },
  computed: {
    ...mapGetters(['getWorkPageAssets', 'getWorkPagesLoadingStatus', 'getWorkPageAssetsLoadingStatus', 'getSelectedCatalogueKey',
      'getSelectedRouteCode', 'getSelectedWorkPage', 'getWebsocketConnection', 'getUserRoles',
      'getWorkPageAssetsErrorStatus', 'getCatalogueData']),
  },
  watch: {
    getWorkPageAssetsErrorStatus(newValue) {
      if (newValue) {
        redirectIfNotEqualsCurrentPath(this.$router, '/error');
      }
    },
  },
  methods: {
    isAssetOfSameCatalogue(catalogueKey) {
      return catalogueKey === this.getSelectedCatalogueKey;
    },
    isAssetOfSameWorkPage(catalogueKey, routeCode, workPageNumber) {
      return catalogueKey === this.getSelectedCatalogueKey
        && routeCode === this.getSelectedRouteCode
        && workPageNumber === this.getSelectedWorkPage;
    },
    isAssetOfSameType(assetType) {
      return assetType === getAssetType(this.getUserRoles, this.$router);
    },
  },
};
