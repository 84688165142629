<template>
  <v-dialog
    v-if="image.length"
    v-model="modalOpen"
    width="1000"
    hide-overlay
    :retain-focus="false"
    class="user-guide-dialog-box"
  >
    <v-card>
      <v-card-title data-test="user-guide-dialog-title" class="text-h5 grey lighten-2">
        {{ $t('userGuide.label') }}
        <v-spacer></v-spacer>
        <CloseButton @click="modalOpen = false"/>
      </v-card-title>
      <v-card-subtitle data-test="user-guide-dialog-subtitle" class="my-2 text-h6 text-center pa-0">
        {{ $t(`userGuide.${content}[${index}].subtitle`) }}
      </v-card-subtitle>
      <v-img
        height="550"
        contain
        :src="getImage"
        data-test="user-guide-dialog-image"
      ></v-img>
      <v-card-text data-test="user-guide-dialog-content">
        {{ $t(`userGuide.${content}[${index}].content`) }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="user-guide-dialog-button-group"
          v-if="index!==image.length-1"
          text
          @click="modalOpen = false"
          data-test="user-guide-skip-button"
        >
          Skip
        </v-btn>
        <v-btn
          class="user-guide-dialog-button-group"
          data-test="user-guide-prev-button"
          v-if="index!==0"
          text
          @click="index--"
        >
          Prev
        </v-btn>
        <v-btn
          class="user-guide-dialog-button-group"
          data-test="user-guide-next-button"
          v-if="index!==image.length-1"
          text
          @click="index++"
        >
          Next
        </v-btn>
        <v-btn
          class="user-guide-dialog-button-group"
          data-test="user-guide-finish-button"
          v-if="index===image.length-1"
          text
          @click="modalOpen= false"
        >
          Finish
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CloseButton from '@/components/CloseButton.vue';

export default {
  name: 'UserGuideDialog',
  data() {
    return {
      index: 0,
    };
  },
  components: {
    CloseButton,
  },
  props: {
    value: Boolean,
    userDialogLocalStorage: Boolean,
    image: Array,
    content: String,
  },
  computed: {
    modalOpen: {
      get() {
        return this.value || this.userDialogLocalStorage;
      },
      set(value) {
        this.$emit('input', value);
        this.$emit('toggleDialog');
      },
    },
    getImage() {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`../assets${this.image[this.index].image}`);
    },
  },
};
</script>

<style scoped>
.user-guide-dialog-button-group {
  background-color: #F00020;
  color: white;
}
</style>
