<template>
  <v-container className="pa-0" fluid>
    <WorkPageAssetsLayoutSelector class="ml-auto float-end"/>
    <v-progress-linear v-if="loading" indeterminate color="blue"/>
    <div v-if="!loading">
      <div v-if="uploadedAssets.length === 0 && isGridLayoutMode">
        <p class="text-center mt-16">No Assets Available</p>
      </div>
      <div v-else>
        <WorkPageAssetsGridLayout v-show="isGridLayoutMode" :assets="uploadedAssets"/>
      </div>
      <WorkPageAssetsListLayout v-show="isListLayoutMode" :assets="uploadedAssets"/>
    </div>
  </v-container>
</template>

<script>
import WorkPageAssetsGridLayout from '@/components/photographer/WorkPageAssetsGridLayout.vue';
import WorkPageAssetsLayoutSelector from '@/components/photographer/WorkPageAssetsLayoutSelector.vue';
import { mapGetters } from 'vuex';
import { WORK_PAGE_ASSETS_LAYOUT_MODE } from '@/modules/constants';
import WorkPageAssetsListLayout from './WorkPageAssetsListLayout.vue';

export default {
  name: 'Assets',
  components: {
    WorkPageAssetsListLayout,
    WorkPageAssetsGridLayout,
    WorkPageAssetsLayoutSelector,
  },
  props: {
    loading: Boolean,
    uploadedAssets: Array,
  },
  computed: {
    ...mapGetters(['getWorkPageAssetsLayoutMode']),
    isGridLayoutMode() {
      return this.getWorkPageAssetsLayoutMode === WORK_PAGE_ASSETS_LAYOUT_MODE.GRID_VIEW;
    },
    isListLayoutMode() {
      return this.getWorkPageAssetsLayoutMode === WORK_PAGE_ASSETS_LAYOUT_MODE.LIST_VIEW;
    },
  },
};
</script>

<style>
</style>
