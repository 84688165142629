<template>
  <button @click="$emit('collapse')" class="collapse-button">
    <span :class="getButtonSymbol"></span>
  </button>
</template>

<script>
export default {
  name: 'CollapseButton',
  props: {
    status: Boolean,
  },
  computed: {
    getButtonSymbol() {
      return this.status ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down';
    },
  },
};
</script>

<style scoped lang="scss">
.collapse-button .retry-button {
  border: 1px solid $black50;
  background: $black50;
  color: $pl_color-white100;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
</style>
