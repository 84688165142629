import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import Keycloak from 'keycloak-js';
import App from './App.vue';
import router from './router';
import store from './store';

const {
  VUE_APP_KEYCLOAK_AUTH_SERVER_URL,
  VUE_APP_KEYCLOAK_CLIENT_ID,
  VUE_APP_KEYCLOAK_REALM_NAME,
} = process.env;

const initOptions = {
  url: VUE_APP_KEYCLOAK_AUTH_SERVER_URL, realm: VUE_APP_KEYCLOAK_REALM_NAME, clientId: VUE_APP_KEYCLOAK_CLIENT_ID, onLoad: 'login-required',
};

const keycloak = new Keycloak(initOptions);

// TODO: Remove checkLoginIFrame: false and edit client name without space
keycloak.init({ onLoad: initOptions.onLoad, checkLoginIframe: false }).then(async (auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    console.info('authenticated');
    await store.dispatch('initKeycloak', keycloak);

    if (!localStorage.getItem('showUserGuideDialog')) {
      localStorage.setItem('showUserGuideDialog', true);
    }
    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount('#app');
  }

  setInterval(() => {
    keycloak.updateToken(20).then(async (refreshed) => {
      if (refreshed) {
        await store.dispatch('initKeycloak', keycloak);
      }
    }).catch(() => {
      console.error('Failed to refresh token');
    });
  }, 6000);
}).catch(() => {
  console.error('Authenticated Failed');
});
