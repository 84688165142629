import {
  ASSET_UPLOAD_STATUS,
  FILE_NAME_VALIDATION_STATUS,
} from '@/modules/constants';
import getSignedUrl from '@/modules/clients/signedUrlClient';
import uploadFile from '@/modules/clients/s3Client';
import { updateAssetS3UploadStatus } from '@/modules/clients/workPageAssetsClient';

export const uploadAssetAndUpdateState = async ({ commit, dispatch }, assetDetails) => {
  const {
    catalogueKey,
    routeCode,
    workPageNumber,
    fileName,
    files,
    fileIndex,
    fileSize,
    metadata,
    type,
    validationErrorMessage,
  } = assetDetails;
  commit('updateAssetInFlight', {
    catalogueKey,
    workPageNumber,
    routeCode,
    fileName,
    file: files[fileIndex],
    metadata,
    type,
    uploadProgress: 0,
    totalSize: files[fileIndex].size,
    s3UploadStatus: ASSET_UPLOAD_STATUS.IN_PROGRESS,
    [FILE_NAME_VALIDATION_STATUS.name]: FILE_NAME_VALIDATION_STATUS.status.VALID,
    validationErrorMessage,
  });
  const {
    uploadUrl: signedUrl,
    success: signedUrlSuccess,
    fileName: uuid,
  } = await getSignedUrl({
    catalogueKey, routeCode, workPageNumber, fileName, type, fileSize, metadata,
  });
  let uploadSuccess = false;
  let s3UploadStatus = ASSET_UPLOAD_STATUS.FAILED;
  const updateUploadProgress = async (data) => {
    commit('updateAssetInFlight', {
      catalogueKey,
      routeCode,
      workPageNumber,
      fileName,
      file: files[fileIndex],
      metadata,
      type,
      uploadProgress: data.loaded,
      totalSize: files[fileIndex].size,
      s3UploadStatus: ASSET_UPLOAD_STATUS.IN_PROGRESS,
      [FILE_NAME_VALIDATION_STATUS.name]: FILE_NAME_VALIDATION_STATUS.status.VALID,
      validationErrorMessage,
    });
    await dispatch('calculateUploadSpeed');
  };
  if (signedUrlSuccess) {
    const { success } = await uploadFile(
      signedUrl, files[fileIndex], updateUploadProgress,
    );
    uploadSuccess = success;
    s3UploadStatus = uploadSuccess && signedUrlSuccess
      ? ASSET_UPLOAD_STATUS.DONE : ASSET_UPLOAD_STATUS.FAILED;
    if (s3UploadStatus === ASSET_UPLOAD_STATUS.FAILED) {
      await updateAssetS3UploadStatus({
        catalogueKey,
        routeCode,
        workPageNumber,
        fileName: uuid,
      });
    }
  }
  commit('updateAssetInFlight', {
    catalogueKey,
    routeCode,
    workPageNumber,
    fileName,
    file: files[fileIndex],
    metadata,
    type,
    uploadProgress: s3UploadStatus === ASSET_UPLOAD_STATUS.DONE ? files[fileIndex].size : 0,
    totalSize: files[fileIndex].size,
    s3UploadStatus,
    [FILE_NAME_VALIDATION_STATUS.name]: FILE_NAME_VALIDATION_STATUS.status.VALID,
    validationErrorMessage,
  });
};

const extractCatalogueStructure = (filename) => {
  const catalogueKey = filename.substring(0, 7);
  const routeCode = filename.substring(7, 10);
  const workPageNumber = filename.substring(10, 13);
  return {
    catalogueKey,
    routeCode,
    workPageNumber,
  };
};

export const extractDocumentStatus = (filename) => {
  const fileNameDetails = (filename.split('.')[0]).split('_');
  let documentStatus = fileNameDetails[2];
  // eslint-disable-next-line no-restricted-globals
  if (fileNameDetails[3] && isNaN(fileNameDetails[3])) {
    documentStatus = `${documentStatus}_${fileNameDetails[3]}`;
  }
  return documentStatus;
};

export const extractCatalogueDetailsForDesignDoc = (filename) => {
  const { catalogueKey, routeCode, workPageNumber } = extractCatalogueStructure(filename);
  const pagina = filename.split('_')[1].split('.')[0];
  const documentStatus = extractDocumentStatus(filename);
  return {
    catalogueKey, routeCode, workPageNumber, metadata: { pagina, documentStatus },
  };
};

export const extractCatalogueDetailsForImage = (filename) => {
  const { catalogueKey, routeCode, workPageNumber } = extractCatalogueStructure(filename);
  const figureNumber = filename.substring(13).split('A')[1];
  return {
    catalogueKey, routeCode, workPageNumber, metadata: { figureNumber },
  };
};
