<template>
  <v-btn
    class="catalogue-production-button"
    :class="buttonColorClass" @click="$emit('click', $event)">
    <p class="catalogue-production-button-label">{{ label }}</p>
  </v-btn>
</template>

<script>
export default {
  name: 'Button',
  props: {
    label: String,
    color: String,
  },
  computed: {
    buttonColorClass() {
      switch (this.color) {
        case 'black':
          return 'catalogue-production-button-black';
        case 'red':
          return 'catalogue-production-button-red';
        default:
          return 'catalogue-production-button-red';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.catalogue-production-button-black.v-btn {
  background-color: $black50;
}
.catalogue-production-button-red.v-btn {
  background-color: $red100;
}
.catalogue-production-button.v-btn {
  text-transform: none;
  color: $pl_color-white100;
  height: 40px;
  font-size: 16px;
  border-radius: 8px;
  margin-right: 10px;
  min-width: 100px;
}
.catalogue-production-button-label {
  margin: 0 auto;
}
</style>
