<template>
  <v-container class="catalogue-dashboard pt-16">
    <h1 class="catalogue-dashboard-title">{{ $t('title.catalogue') }}</h1>
    <div class="row">
      <div class="col-6">
        <p class="mt-6 mb-10">
          {{$t('message.dashboard', { assetType: $t(`assetName['${getType}']`) }) }}
        </p>
      </div>
      <div class="col-6" v-if="!getCataloguesLoadingStatus">
        <v-text-field
          class="search-input"
          style="width: 92%"
          v-model="searchCatalogue"
          label="Search Catalogue"/>
      </div>
    </div>
    <div v-if="getCataloguesLoadingStatus">
      <v-progress-linear
        indeterminate
        color="blue"
      ></v-progress-linear>
    </div>
    <div class="pt-4 d-flex flex-wrap" v-else>
      <v-hover
        v-slot="{ hover }"
        class="mx-2"
        v-for="catalogue in filteredCatalogues"
        :key=catalogue.CatalogueKey
      >
        <v-card
          :to="`${$route.path}/${catalogue.CatalogueKey}`"
          :width="125"
          :data="catalogue"
          outlined
          class="cut-out-style my-2"
        >
          <v-expand-transition>
            <div v-if="hover"
                 class="d-flex transition-fast-in-fast-out grey darken-1 v-card--reveal display-3"
            />
          </v-expand-transition>
          <div class="top-section"/>
          <v-card-actions>
            <v-btn
              text
              plain
              class="flex"
              color="black"
            >
              {{ catalogue.CatalogueKey }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-hover>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getAssetType } from '@/modules/getApplicationUserRole';

export default {
  name: 'CatalogueDashboard',
  data() {
    return {
      hovering: false,
      searchCatalogue: '',
    };
  },
  computed: {
    ...mapGetters(['getCataloguesLoadingStatus', 'getUserRoles']),
    ...mapState([
      'catalogues',
    ]),
    filteredCatalogues() {
      return this.catalogues
        .filter((catalogue) => catalogue.CatalogueKey.toLowerCase()
          .includes(this.searchCatalogue.toLowerCase()));
    },
    spacing() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '';
        case 'sm': return '';
        case 'md': return 'px-8';
        case 'lg': return 'px-8';
        case 'xl': return 'px-8';
        default: return 'px-8';
      }
    },
    getType() {
      return getAssetType(this.getUserRoles, this.$router);
    },
  },
};
</script>

<style scoped lang="scss">
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
  height: 100%;
}
.cut-out-style {
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 10%, 90% 0);
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 10%, 90% 0);
}
.top-section {
  height: 90px;
  background-color: $black50;
}
.v-btn {
  font-weight: lighter;
  color: $pl_color-black100;
}
.catalogue-dashboard {
  background: $grey25;
}
.catalogue-dashboard-title {
  font-weight: 100;
  font-family: OttoSansThin, serif;
  font-size: 34px;
}
</style>
