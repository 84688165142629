import {
  ASSET_UPLOAD_STATUS,
} from '@/modules/constants';

export const getUploadedWorkPageAssets = (assets) => assets.filter(
  (asset) => asset.s3UploadStatus === ASSET_UPLOAD_STATUS.DONE,
);

export const isAssetCancelled = (assetInfo, assetsInFlight) => {
  const {
    fileName,
    catalogueKey,
    workPageNumber,
    routeCode,
  } = assetInfo;
  const asset = assetsInFlight.find((assetInFlight) => assetInFlight.fileName === fileName
    && assetInFlight.catalogueKey === catalogueKey
    && assetInFlight.workPageNumber === workPageNumber
    && assetInFlight.routeCode === routeCode);
  return asset.s3UploadStatus === ASSET_UPLOAD_STATUS.CANCELLED;
};
