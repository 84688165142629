<template>
  <v-main class="default-app">
    <div class="error-content d-flex align-center justify-center">
      <v-icon class="error-icon mx-4" color="grey">
        mdi-image-broken-variant
      </v-icon>
      <div>
        <p>Oops. That's an error.</p>
        <p>The requested URL was not found on the server. That's all we know.</p>
        <v-btn class="error-home-button mt-4" href="/" >Go home</v-btn>
      </div>
    </div>
  </v-main>
</template>

<script>
export default {
  name: 'Error.vue',
};
</script>

<style scoped lang="scss">
.default-app {
  font-family: OttoSansThin;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $grey25;
  width: 100%;
}
.error-content {
  height: 500px;
}
.error-icon {
  font-size: 256px;
}
.error-home-button.v-btn {
   background-color: $red100;
   text-transform: none;
   color: $pl_color-white100;
   height: 40px;
   font-size: 16px;
   border-radius: 8px;
 }
</style>
