<template>
  <v-main class="pt-16">
    <v-container>
      <v-progress-linear v-if="loading" indeterminate color="blue"/>
      <div v-else>
        <h1 class="route-code-title">{{ title }}</h1>
        <p class="mt-6 mb-16">
          {{$t('message.routeCode', { assetType: $t(`assetName['${getType}']`) }) }}
        </p>
        <div v-if="getWorkPagesLoadingStatus">
          <v-progress-linear
            indeterminate
            color="blue"
          ></v-progress-linear>
        </div>
        <div v-for="workPage in workPages"
             :key="workPage">
          <CatalogueDataEntry
            :entry-key="workPage"
            :to="`${$route.path}/${workPage}`"
          />
        </div>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import { getAssetType } from '@/modules/getApplicationUserRole';
import CatalogueDataEntry from './CatalogueDataEntry.vue';
import { redirectIfNotEqualsCurrentPath } from '../../modules/vueUtils';

export default {
  name: 'RouteCode',
  components: { CatalogueDataEntry },
  computed: {
    ...mapGetters(['getSelectedCatalogueKey',
      'getSelectedRouteCode',
      'getRouteCodeData',
      'getWorkPagesLoadingStatus',
      'getUserRoles',
      'getCatalogueData']),
    title() {
      return `${this.getSelectedCatalogueKey}${this.getSelectedRouteCode}`;
    },
    workPages() {
      const routeCodeData = this.getRouteCodeData(
        this.getSelectedCatalogueKey, this.getSelectedRouteCode,
      );
      return routeCodeData ? Object.keys(routeCodeData?.workPages).sort(
        (workPage1, workPage2) => workPage1 - workPage2,
      ) : routeCodeData;
    },
    getType() {
      return getAssetType(this.getUserRoles, this.$router);
    },
    loading() {
      return this.getWorkPagesLoadingStatus;
    },
  },
  watch: {
    getWorkPagesLoadingStatus(newValue) {
      if (!newValue) {
        if (this.getRouteCodeData(
          this.getSelectedCatalogueKey, this.getSelectedRouteCode,
        ) === undefined) {
          redirectIfNotEqualsCurrentPath(this.$router, '/error');
        }
      }
    },
  },
  created() {
    if (this.getCatalogueData !== undefined
      && !Object.keys(this.getCatalogueData).includes(this.getSelectedRouteCode)) {
      redirectIfNotEqualsCurrentPath(this.$router, '/error');
    }
  },
};
</script>

<style scoped lang="scss">
.route-code-title {
  font-weight: 100;
  font-family: OttoSansThin, serif;
  font-size: 34px;
}
</style>
