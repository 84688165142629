import { mapGetters } from 'vuex';
import { ASSET_TYPE, THUMBNAIL_EXPIRY_MILLIS } from '@/modules/constants';
import { getAssetType } from '@/modules/getApplicationUserRole';

export default {
  data() {
    return {
      thumbnailsLastRefreshedAt: new Date().getTime(),
    };
  },
  methods: {
    reloadThumbnails() {
      if ((new Date().getTime() - this.thumbnailsLastRefreshedAt) > THUMBNAIL_EXPIRY_MILLIS) {
        if (this.assetType === ASSET_TYPE.IMAGE) {
          this.$store.dispatch('getWorkPageThumbnails', {
            catalogueKey: this.getSelectedCatalogueKey,
            routeCode: this.getSelectedRouteCode,
            workPageNumber: this.getSelectedWorkPage,
            type: this.assetType,
          });
        }
        if (this.assetType === ASSET_TYPE.DESIGN_DOC) {
          this.$store.dispatch('getCatalogueThumbnails', {
            catalogueKey: this.getSelectedCatalogueKey,
            type: this.assetType,
          });
        }
        this.thumbnailsLastRefreshedAt = (new Date()).getTime();
      }
    },
  },
  computed: {
    ...mapGetters([
      'getUserRoles',
      'getListViewItemsPerPage',
      'getSelectedCatalogueKey',
      'getSelectedRouteCode',
      'fetchAssetsLoadingStatus',
      'getSelectedWorkPage',
      'getAssetThumbnails']),
    assetType() {
      return getAssetType(this.getUserRoles, this.$router);
    },
    loading() {
      return this.fetchAssetsLoadingStatus;
    },
  },
};
