<template>
  <v-hover
    v-slot="{ hover }"
    class="mx-2"
  >
    <v-card
      :to="to"
      :elevation="hover ? 1 : 0"
      class="catalogue-data-entry-title pl-4 d-flex align-center mb-2 rounded-lg"
    >
      {{entryKey}}
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'CatalogueDataEntry',
  props: ['entryKey', 'to'],
};
</script>

<style scoped lang = "scss">
.catalogue-data-entry-title {
  width: 100%;
  height: 48px;
  background: $pl_color-white100;
  border-radius: 8px;
}
</style>
