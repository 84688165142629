<template>
  <div :class="statusClass" class="status-icon">
    <v-progress-circular
      indeterminate
      :size="25"
      :width="2"
      color="grey"
      v-if="status === 'pending'"
    ></v-progress-circular>
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  name: 'StatusIcon',
  props: {
    status: String,
  },
  computed: {
    statusClass() {
      switch (this.status) {
        case 'success': return 'status-icon-success';
        case 'failed': return 'status-icon-failed';
        case 'pending': return 'status-icon-pending';
        case 'inactive': return 'status-icon-inactive';
        case 'other': return 'status-icon-other';
        default: return 'status-icon-inactive';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.status-icon {
  svg{
    width: 24px;
    height: 24px;
    fill: $pl-color-white100;
  }
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.status-icon-success {
  background: $pl_color-success;
}
.status-icon-inactive {
  background: $grey50;
}
.status-icon-failed {
  background: $red100;
}
.status-icon-pending {
  background: $grey50;
}
.status-icon-other {
  background: $pl_color-blue100;
}
</style>
