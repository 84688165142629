<template>
  <v-menu
    bottom
    min-width="200px"
    rounded
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        outlined text
        id="avatarButton"
        v-on="on"
        class="mr-1 mt-2"
      >
        <v-avatar
          color="black"
          size="48"
          id = "avatarIcon"
        >
          <span class="white--text" id="avatarInitials">{{ getInitials() }}</span>
        </v-avatar>
      </v-btn>
    </template>
    <v-card class="card-style">
      <v-list-item-content class="justify-center" id="profileCard">
        <div class="mx-auto text-center">
          <v-avatar
            color="black"
            id="vCardAvatar"
          >
            <span class="white--text" id="vCardAvatarInitials">{{ getInitials() }}</span>
          </v-avatar>
          <h3 class="mt-1">{{ getFullName() }}</h3>
          <p class="mt-1">
            {{ getEmail() }}
          </p>
          <v-divider class="my-3"></v-divider>
          <v-btn
            depressed
            rounded
            text
            id="logoutButton"
            @click="logOut"
          >
            {{ $t('actions.logOut') }}
          </v-btn>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import { redirectIfNotEqualsCurrentPath } from '@/modules/vueUtils';

export default {
  name: 'Avatar',
  methods: {
    getInitials() {
      if (this.keycloak && this.keycloak.tokenParsed && this.keycloak.tokenParsed.name) {
        const firstAndLastNames = this.keycloak.tokenParsed.name.split(' ');
        return `${firstAndLastNames[0].slice(0, 1)}${firstAndLastNames[1].slice(0, 1)}`;
      }
      return 'NA';
    },
    getFullName() {
      return this.keycloak.tokenParsed.name;
    },
    getEmail() {
      return this.keycloak.tokenParsed.email;
    },
    logOut() {
      const connection = this.getWebsocketConnection;
      if (connection) {
        connection.close();
      }
      redirectIfNotEqualsCurrentPath(this.$router, '/');
      localStorage.clear();
      this.keycloak.logout();
    },
  },
  computed: {
    ...mapState([
      'keycloak',
    ]),
    ...mapGetters([
      'getWebsocketConnection',
    ]),
  },
};
</script>

<style scoped lang="scss">
#avatarInitials, #vCardAvatarInitials {
  @include font($font125, OttoSans);
}
</style>
