<template>
  <div class="catalogue-hierarchy mx-8">
    <HierarchyEntry
      @collapse="collapseElement(getSelectedCatalogueKey)"
      :collapse="showElement(getSelectedCatalogueKey)"
      :name="getSelectedCatalogueKey" level="CATALOGUE" class="mt-4"/>
    <v-progress-linear class="mt-2" v-if="getWorkPagesLoadingStatus" indeterminate color="blue"/>
    <v-expand-transition v-else>
    <div v-if="showElement(getSelectedCatalogueKey)">
      <div v-for="route in getCatalogueData" :key="route.routeCode">
        <HierarchyEntry
          :name="route.routeCode"
          :collapse="showElement(route.routeCode)"
          @collapse="collapseElement(route.routeCode)"
          level="ROUTECODE" class="mt-4"/>
        <v-expand-transition>
          <div v-if="showElement(route.routeCode)">
            <HierarchyEntry
              v-for="workPage in getWorkPagesForRouteCode(route.workPages)" :key="workPage"
              :name="workPage"
              :service-provider="route.workPages[workPage].serviceProvider"
              level="WORKPAGE" class="mt-4 ml-16"/>
          </div>
        </v-expand-transition>
      </div>
    </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HierarchyEntry from '@/components/printUser/HierarchyEntry.vue';

export default {
  name: 'CatalogueHierarchy',
  components: { HierarchyEntry },
  computed: {
    ...mapGetters([
      'getSelectedCatalogueKey', 'getCatalogueData', 'getWorkPagesLoadingStatus', 'getCollapseStatus',
    ]),
  },
  methods: {
    showElement(key) {
      return !this.getCollapseStatus(key);
    },
    collapseElement(key) {
      this.$store.dispatch('toggleCollapseStatus', { catalogueHierarchyElement: key });
    },
    getWorkPagesForRouteCode(workPages) {
      return Object.keys(workPages).sort((workPage1, workPage2) => workPage1 - workPage2);
    },
  },
};
</script>

<style scoped lang="scss">
.catalogue-title {
  font-size: 14px;
  font-weight: 600;
}

.hierarchy-entry {
  justify-content: space-between;
  font-size: 12px;
  height: 48px;
  background: $pl_color-white100;
  border-radius: 10px;
  font-family: OttoSans;
  position: relative;
  z-index: 1;
  width: 100%
}

.catalogue-hierarchy:after {
  content: "";
  position: absolute;
  z-index: 0;
  top: 24px;
  bottom: 16px;
  left: 56px;
  border-left: 1px dotted $black100;
}

.catalogue-hierarchy {
  position: relative;
}

.delete-icon {
  width: 50px;
}
</style>
