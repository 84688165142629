<template>
  <Assets :loading="loading" :uploadedAssets="uploadedAssets"></Assets>
</template>

<script>
import { getUploadedWorkPageAssets } from '@/modules/assetSelection';
import websocketHandler from '@/mixins/websocketHandler';
import Assets from '@/components/photographer/Assets.vue';

export default {
  name: 'WorkPage',
  components: {
    Assets,
  },
  mixins: [websocketHandler],
  computed: {
    loading() {
      return this.getWorkPageAssetsLoadingStatus || this.getWorkPagesLoadingStatus;
    },
    uploadedAssets() {
      return getUploadedWorkPageAssets(this.getWorkPageAssets);
    },
  },
  mounted() {
    this.$store.dispatch('webSocketSendMessage', {
      action: 'currentWorkPage',
      workPage: JSON.stringify({
        catalogueKey: this.getSelectedCatalogueKey,
        routeCode: this.getSelectedRouteCode,
        workPageNumber: this.getSelectedWorkPage,
      }),
    });
    this.getWebsocketConnection.onmessage = (message) => {
      this.validateAndStoreUpdate(message);
    };
  },
  methods: {
    validateAndStoreUpdate(message) {
      const update = JSON.parse(message.data);
      const { asset, operation, workPage } = update;
      const {
        catalogueKey, routeCode, workPageNumber,
      } = workPage;
      if (this.isAssetOfSameWorkPage(catalogueKey, routeCode, workPageNumber)
        && this.isAssetOfSameType(asset.type)) {
        this.$store.dispatch('updateAssets', {
          catalogueKey: this.getSelectedCatalogueKey,
          routeCode: this.getSelectedRouteCode,
          workPageNumber: this.getSelectedWorkPage,
          operation,
          asset,
        });
      }
    },
  },
};
</script>

<style>
</style>
