import axios from 'axios';
import { CATALOGUE_OPERATION, CATALOGUE_XML_UPLOAD_ENDPOINT } from '@/modules/constants';

const { VUE_APP_GET_ALL_CATALOGUES_API } = process.env;
const messageForCatalogueOperation = (operation) => {
  switch (operation) {
    case CATALOGUE_OPERATION.CATALOGUE_CREATE:
      return 'Catalogue created successfully';
    case CATALOGUE_OPERATION.CATALOGUE_UPDATE:
      return 'Catalogue updated successfully';
    default:
      return 'The xml file was uploaded successfully';
  }
};

export default async (file) => {
  let success = false;
  let message;
  let operation = null;
  let workPageDetails = null;
  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Content-Type': file.type,
    },
  };
  try {
    const url = `${VUE_APP_GET_ALL_CATALOGUES_API}${CATALOGUE_XML_UPLOAD_ENDPOINT}`;
    const response = await axios.post(url, file, config);
    success = response.status === 200;
    operation = response.data.operation;
    message = messageForCatalogueOperation(operation);
    workPageDetails = response.data.workPageDetails;
  } catch (error) {
    console.log('Error uploading file', error);
    const defaultErrorMessage = 'Upload failed due to an error';
    message = error.response?.data.message || defaultErrorMessage;
  }
  return {
    success, message, operation, workPageDetails,
  };
};
